<div class="home-content1 ">
    <div class="home1">
        <div class="container">
            <div class="row">
                <h1>Lorem <span class="top_text_color">ipsum dolor</span> sit amet lorem ipsum</h1>
            </div>
            <div class="row px-5 login_form">
                <div class="col-md-12 col-xs-12 col-sm-12 col-lg-12 ">
                    <form class="m-auto w-50 px-5 py-2" #resetForm="ngForm">
                        <div class=" form form-group ">
                            <h2 class="text-success text-center">Reset Password</h2>

                            <label for="password">Password</label>
                            <input class="form-control" type="password" name="password" placeholder="password"
                                [(ngModel)]="user.password" required minlength="8" #passwordis="ngModel">
                            <div class="danger" *ngIf="passwordis.errors && passwordis.dirty">
                                <div *ngIf="passwordis.errors.required">
                                    required field*
                                </div>
                                <div *ngIf="passwordis.errors.minlength">
                                    weak password
                                </div>
                            </div>
                            <label for="confrimPassword">Confrim Password</label>
                            <input class="form-control" type="text" name="ConfirmPassword" placeholder="confrim password"
                                [(ngModel)]="confirmPassword" required #confirmPasswordis="ngModel">
                            <br>
                            <div *ngIf="submitButtShow">
                                <div *ngIf="!submitting">
                                    <button [disabled]="!resetForm.valid" (click)="submit()"
                                        class="btn btn-primary">submit</button>
                                </div>
                            </div>
                            <button disabled *ngIf="submitting" class="btn btn-info" disabled>submitting...</button>
                        </div>
                    </form>

                </div>
            </div>
        </div>
    </div>
</div>
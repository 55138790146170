import { Component, OnInit,ViewChild, HostListener, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { MdbTablePaginationComponent, MdbTableDirective } from 'angular-bootstrap-md';
import { MsgService } from 'src/app/shared/service/msg.service';
import { environment } from 'src/environments/environment';
import { blogService } from '../services/blog.service';


@Component({
  selector: 'app-view-blogpage',
  templateUrl: './view-blogpage.component.html',
  styleUrls: ['./view-blogpage.component.scss']
})
export class ViewBlogpageComponent implements OnInit, AfterViewInit {
  @ViewChild(MdbTablePaginationComponent, { static: true }) mdbTablePagination: MdbTablePaginationComponent;
  @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective
 
  elements: any = [];
  previous: any = [];
  headElements = ['S.No.', 'Title','Name','Author','Date', 'Image', 'View', 'Delete'];

  ads=[];
  blogImageUrl;
  deleteAsk:Boolean=false;
  //for temporaray
 
  deleteid:any;
   constructor(
    private cdRef: ChangeDetectorRef,
    public msgService:MsgService,
    public blogService:blogService,
    public router:Router,
    ) {
    this.blogImageUrl=environment.ImgUrl;
     }

  ngOnInit() {

    this.mdbTable.setDataSource(this.elements);
    this.elements = this.mdbTable.getDataSource();
    this.previous = this.mdbTable.getDataSource();
    this.getBrands();
  }

  ngAfterViewInit() {
    this.mdbTablePagination.setMaxVisibleItemsNumberTo(50);

    this.mdbTablePagination.calculateFirstItemIndex();
    this.mdbTablePagination.calculateLastItemIndex();
    this.cdRef.detectChanges();
  }
  getBrands(){
    this.elements=[];
    this.blogService.getall().subscribe(
      (data:any)=>{
        
        this.msgService.showSuccess('Data Loading Success!!!');
        if(data.length !=0){
          
          // this.addAds.id=parseInt(data[0].id)+1;
          this.elements=data;
          this.mdbTable.setDataSource(this.elements);
          this.elements = this.mdbTable.getDataSource();
          this.previous = this.mdbTable.getDataSource();
          
        }
      },
      error=>{
        console.log(error)
        this.msgService.showWarning('Data loading error !!!')
      }
    )
  }

  askDelete(i:any, data:any){
    this.deleteAsk=i;
    this.deleteid=data._id;
  }
  deleteModalClose(){
    this.deleteAsk=false;    
    this.deleteid=null;
  }
  
  delete(){
    this.blogService.remove(this.deleteid).subscribe(
      (data:any)=>{
        
        this.msgService.showSuccess('Data deleted successfully');
       
        this.getBrands();
        this.deleteAsk=false;    
      },
      error=>{
        this.msgService.showWarning('Error deleting data')
      }
    )
  }
  view(id:any){
    this.router.navigate(['/dashboard/editBlog/'+id])
  }

}

import { Component, OnInit,ViewChild, HostListener, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { MdbTablePaginationComponent, MdbTableDirective } from 'angular-bootstrap-md';
import { MsgService } from 'src/app/shared/service/msg.service';

import { environment } from 'src/environments/environment';
import { testimonialsInfo } from '../models for dashboard/testimonials.model';
import { testimonialsService } from '../services/testimonials.service';


@Component({
  selector: 'app-add-testimonials',
  templateUrl: './add-testimonials.component.html',
  styleUrls: ['./add-testimonials.component.scss']
})
export class AddTestimonialsComponent implements OnInit, AfterViewInit {
  @ViewChild(MdbTablePaginationComponent, { static: true }) mdbTablePagination: MdbTablePaginationComponent;
  @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective
  elements: any = [];
  previous: any = [];
  headElements = ['S.No.', 'Name','Image','Star','Message', 'Delete'];

  ads=[];
  dataReceived:Boolean=false;

  imageToUpload=[];

  
  deleteAsk:Boolean=false;
  //for temporaray
 
  deleteid:any;

  addBackgroundShow:Boolean=false;
  addTestimonials;
  submitting: Boolean;
 
  fileno=0;
  filesToUpload:any = [];
  imageSelected: boolean = false;
  urls = new Array<string>();
  imageUrl;
  constructor(
    private cdRef: ChangeDetectorRef,
    public msgService:MsgService,
    public adsService:testimonialsService
    ) {
      
      this.addTestimonials=new testimonialsInfo({});
      this.submitting = false;
      this.imageUrl=environment.ImgUrl;
     }

  ngOnInit() {

        this.mdbTable.setDataSource(this.elements);
    this.elements = this.mdbTable.getDataSource();
    this.previous = this.mdbTable.getDataSource();
    this.getBrands();
  }

  ngAfterViewInit() {
    this.mdbTablePagination.setMaxVisibleItemsNumberTo(20);

    this.mdbTablePagination.calculateFirstItemIndex();
    this.mdbTablePagination.calculateLastItemIndex();
    this.cdRef.detectChanges();
  }
  getBrands(){
    this.elements=[];
    this.adsService.getall().subscribe(
      (data:any)=>{
        console.log('the data >>>>', data)
        this.msgService.showSuccess('Data Loading Success!!!');
        if(data.length !=0){
          this.dataReceived=true;
          // this.addAds.id=parseInt(data[0].id)+1;
          this.elements=data;
          this.mdbTable.setDataSource(this.elements);
          this.elements = this.mdbTable.getDataSource();
          this.previous = this.mdbTable.getDataSource();
          
        }else{
          this.dataReceived=false;
          
        }
      },
      error=>{
        this.msgService.showWarning('Data loading error !!!')
      }
    )
  }
  askDelete(i:any, data:any){
    this.deleteAsk=i;
    this.deleteid=data._id;
  }
  deleteModalClose(){
    this.deleteAsk=false;    
    this.deleteid=null;
  }
  
  delete(){
    this.adsService.remove(this.deleteid).subscribe(
      (data:any)=>{
        
        this.msgService.showSuccess('Data deleted successfully');
       
        this.getBrands();
        this.deleteAsk=false;    
      },
      error=>{
        this.msgService.showWarning('Error deleting data')
      }
    )
  }
 

  showAddCategoriesModal(){
    this.addBackgroundShow=true;
  }
  addCategoriesModalClose(){
    this.addBackgroundShow=false;
  }
  
  submit() {
    this.submitting=true;
    console.log('teh submited data is>>>', this.addTestimonials)

    this.adsService.upload(this.addTestimonials,this.filesToUpload).subscribe(
      (data:any)=>{
        this.msgService.showSuccess('Testimonials uploaded successfully');
       this.filter();        

      }, error=>{
        this.msgService.showWarning('Testimonials Upload Error');
        this.filter();
        
      }
    )
    

  }
  filter(){
    this.addBackgroundShow=false;
    this.submitting=false;
    this.filesToUpload=[];
    this.addTestimonials=new testimonialsInfo({})
    this.urls=[];
    this.imageSelected=false;
    this.fileno=0;
    this.getBrands();
  }

  fileChanged(e:any) {
    this.fileno = 0;
    this.urls = [];
    this.filesToUpload = [];
   
    var selectedImages = e.target.files;
    for (let i = 0; i < selectedImages.length; i++) {
      var mimeType = selectedImages[i].type;
      var image = mimeType.split("/")[0];
      var selectedFile=[];
      selectedFile = selectedImages[i];
      
      if (image == 'image') {
        this.filesToUpload.push(selectedFile)
      }
    }
    if (this.filesToUpload) {
      this.fileno = this.filesToUpload.length;

      this.imageSelected = true;
      if (this.fileno > 1) {
        alert('You cannot select files maximum 1. Please Upload Again');
        this.filesToUpload = [];
        this.urls = [];
        this.imageSelected = false;
      } else {
        for (let file of this.filesToUpload) {
          let reader = new FileReader();
          reader.onload = (e: any) => {
            this.urls.push(e.target.result);
          }
          reader.readAsDataURL(file);
        }
      }
    }
  }
  removeUploadedFile() {
    this.filesToUpload = [];
    this.urls = [];
    this.fileno = 0;
    this.imageSelected = false;
  }
  removeNew(i:any) {
    this.filesToUpload.splice(i, 1);
    this.urls.splice(i, 1);
    this.fileno = this.fileno - 1;
    if (this.fileno != 0) {
      this.imageSelected = true;
    } else {
      this.imageSelected = false;
    }
  }

}

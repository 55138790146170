export class User {
  fullName:string;
  email:string;  
  password:string;
  username:string;
  address:string;
  image:String;
  phoneNumber:number;
  accountType:String;
  gender:string;
  token:String;
  
 citizenshipNo:String;
 lastLoggedIn:Date;
 newPassword:String;
 orgName:String;
  constructor(obj:any){
      this.username=obj.username||'';
      this.password=obj.password||'';
      this.fullName=obj.fullName||'';
      this.address=obj.address||'';
      this.phoneNumber=obj.phoneNumber||'';
      this.email=obj.email||'';
      this.gender=obj.gender||'';
      this.image=obj.image||'';
      this.accountType=obj.accountType||'';
      this.citizenshipNo=obj.citizenshipNo||'';
      this.lastLoggedIn=obj.lastLoggedIn||'';
      this.newPassword=obj.newPassword||'';
      this.orgName=obj.orgName||'';
      this.token=obj.token||'';
  }
}
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-anzsco-code',
  templateUrl: './anzsco-code.component.html',
  styleUrls: ['./anzsco-code.component.scss']
})
export class AnzscoCodeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from 'src/app/shared/models/user.model';
import { MsgService } from 'src/app/shared/service/msg.service';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.scss']
})
export class ForgotpasswordComponent implements OnInit {
  btnclicked:boolean=false; 
  user;
  constructor(
    public activeRoute:ActivatedRoute,
    public router:Router,
    public userService:AuthService,
    public msgService:MsgService,
  ) {
    this.user=new User({});
   }

  ngOnInit(): void {
    document.documentElement.scrollTop=0;
  }
  getdata(){
    this.btnclicked=true;
    this.userService.forgotPassword(this.user).subscribe(
      (data:any)=>{
        this.msgService.showSuccess('Reset link send.');
        this.btnclicked=false;
        this.router.navigate(['/user/dashboard']);
      },
      (error:any)=>{
        this.msgService.showWarning('Error in sending reset-password link');       
      }
    )
  }

}

import { Component, OnInit } from '@angular/core';
import { consultationForm } from '../models/consultation.model';
import { Router } from '@angular/router';
import { consultationService } from 'src/app/dashboard/services/consultation.service';
import { MsgService } from 'src/app/shared/service/msg.service';
import { sampleRequestInfo } from 'src/app/dashboard/models for dashboard/sampleReq.model';
import { sampleReqService } from 'src/app/dashboard/services/sampleReq.service';

@Component({
  selector: 'app-sample',
  templateUrl: './sample.component.html',
  styleUrls: ['./sample.component.scss']
})
export class SampleComponent implements OnInit {
  submitting:Boolean=false;
  consultationForm;
  requestForSample:Boolean=false;
  sampleReqform;
  samplereqSubmitting:Boolean=false;
  constructor(
    public router:Router,
    public msgService:MsgService,
    public consultationService:consultationService,
    public sampleReqService:sampleReqService
  ) {
    this.consultationForm=new consultationForm({});
    this.sampleReqform=new sampleRequestInfo({})
   }

  ngOnInit(): void {
  }
  submitConsultation(){
    this.consultationService.add(this.consultationForm).subscribe(
      (data:any)=>{
        this.msgService.showSuccess('Thankyou for your inquiry. We will get back to you soon.');
        this.consultationForm=new consultationForm({});
      },error=>{
        this.msgService.showWarning('Error! Please try again later.')
      }
    )
  }
  showsampleReqModal(){
    this.requestForSample=true;
  }
  addCategoriesModalClose(){
    this.requestForSample=false;
  }
  submitSampleReq(){
    this.samplereqSubmitting=true;
    this.sampleReqService.add(this.sampleReqform).subscribe(
      (data:any)=>{
        this.msgService.showSuccess('Thankyou For your request. We will get Back to you soon.');
        this.filterSampleReq()
      },error=>{
        this.msgService.showWarning('Error. Please try again later.')
        this.filterSampleReq()
      }
    )
  }
  filterSampleReq(){
    this.requestForSample=false;
    this.sampleReqform=new sampleRequestInfo({})
    this.samplereqSubmitting=false;
  }

}

<div class="sidebar">
    <app-sidebar></app-sidebar>
  </div>
  <div class="main">
      <div class="bg">
          <div class="row">
              <div class="col-12">
                  <h1 class="text-center p-5 my-5">Welcome to Client Side</h1>
              </div>
          </div>
      </div>
      <div class="row">
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 mt-3">
              <div class="heading-text ">
                  <h4 class="text-center text-danger">Sample Req List</h4>
              </div>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              
          </div>
      </div>
          <div class="row mt-3">
              <div class="container">
                  <table mdbTable #tableEl="mdbTable" class="z-depth-1">
                    <thead>
                    <tr>
                      <th *ngFor="let head of headElements; let i = index" scope="col">{{head}}
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let el of elements; let i = index">
                      <th *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex"
                          scope="row">{{i+1}}</th>
                      <td *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex">{{el.name}}</td>
                      <td *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex">{{el.email}}</td>
                      <td *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex">{{el.phoneNumber}}</td>
                      <td *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex">{{el.sampleName}}</td>
                    </tr>
                    </tbody>
                    <tfoot class="grey lighten-5 w-100">
                    <tr>
                      <td colspan="4">
                        <mdb-table-pagination [tableEl]="tableEl" [searchDataSource]="elements"></mdb-table-pagination>
                      </td>
                    </tr>
                    </tfoot>
                  </table>
                </div>
          </div>
  </div>
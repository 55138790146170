import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { consultationService } from 'src/app/dashboard/services/consultation.service';
import { MsgService } from 'src/app/shared/service/msg.service';
import { consultationForm } from '../models/consultation.model';


@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit {
  submitting:Boolean=false;
  consultationForm;
  constructor(
    public router:Router,
    public msgService:MsgService,
    public consultationService:consultationService
  ) {
    this.consultationForm=new consultationForm({});
   }

  ngOnInit(): void {
  }
  submitConsultation(){
    this.consultationService.add(this.consultationForm).subscribe(
      (data:any)=>{
        this.msgService.showSuccess('Thankyou for your inquiry. We will get back to you soon.');
        this.consultationForm=new consultationForm({});
      },error=>{
        this.msgService.showWarning('Error! Please try again later.')
      }
    )
  }

}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss']
})
export class PricingComponent implements OnInit {
  elements: any = [
    {title: 'Complete CDR Writing (3 Career Episode, Summary Statement, CPD)',
    fourweekprice: '599 AUD',  twoweekprice:'799 AUD', oneWeekPrice:'999 AUD'},
    {title: 'Summary Statement Writing',
    fourweekprice: '199 AUD', twoweekprice:'249 AUD', oneWeekPrice:'299 AUD'},
    {title: 'CDR Writing',
    fourweekprice: '50 AUD',  twoweekprice:'75 AUD',  oneWeekPrice:'99 AUD'},
    {title: 'Academic Project Writing (each)',
    fourweekprice: '199 AUD', twoweekprice:'249 AUD',  oneWeekPrice:'299 AUD'},
    {title: 'CDR Review',
    fourweekprice: '99 AUD', twoweekprice:'149 AUD',  oneWeekPrice:'199 AUD'},
    {title: 'CDR Plagiarism checking and removal',
    fourweekprice: '99 AUD', twoweekprice:'149 AUD',  oneWeekPrice:'199 AUD'},
    {title: 'Resume Writing',
    fourweekprice: '99 AUD', twoweekprice:'149 AUD',  oneWeekPrice:'149 AUD'},
    {title: 'EA Explanation Letter Writing',
    fourweekprice: '149 AUD', twoweekprice:'149 AUD',  oneWeekPrice:'149 AUD'},
    {title: 'EA Portal Upload',
    fourweekprice: '99 AUD', twoweekprice:'99 AUD',  oneWeekPrice:'299 AUD'},
  ];

  headElements = ['CDR Services', '21 Days', '14 Days', '7 Days'];
  constructor() { }

  ngOnInit(): void {
  }

}

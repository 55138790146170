export class expertsInfo {
    name:String;
    details:String;
    image:String;
    isCEO:Boolean;
    ceoMessage:String;
    
    
    
  
    constructor(obj:any){
        this.name=obj.name||'';
        this.details=obj.email||'';
        this.image=obj.image||'';
        this.isCEO=obj.isCEO||'';
        this.ceoMessage=obj.ceoMessage||'';
       
        
    }
  }
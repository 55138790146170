import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-activateaccount',
  templateUrl: './activateaccount.component.html',
  styleUrls: ['./activateaccount.component.scss']
})
export class ActivateaccountComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

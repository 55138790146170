export class consultationForm {
    name:string;
    email:string;
    phone:String;
    date:Object;   

    cyear:Number;
    cmonth:Number;
    cday:Number;
  
  constructor(obj:any){
      this.name=obj.name||'';
      this.email=obj.email||'';
      this.phone=obj.phone||'';
      this.date=obj.date||'';
      this.cyear=obj.cyear||'';
      this.cmonth=obj.cmonth||'';
      this.cday=obj.cday||'';
  }
}
<div class="about_Us">
  <div class="cdrWriting">
    <div class="container">
      <div class="row p-4">
        <div class="col-xl-6 col-lg-6 col-md-7 col-sm-12">
          <div class="cdrWriting_heading">
            <h1><span>CDR</span><br />Writing Service</h1>
          </div>
          <div class="cdrWriting_details mt-3">
            <p>
              We help engineers to prepare their Competency Demonstration Report
              (CDR) which they need to submit to Engineers Australia (EA) as a
              part of the Skill Assessment and Australian Immigration process.
              We have a highly professional team of writers and engineers who
              help you prepare the CDR and write your Career Episodes – and get
              them right in your first attempt. We purposes at a very affordable
              price. These sample CDRs have already been assessed positively by
              EA, so be wary of copying or pasting any material from them or
              using the exact projects or career episodes they embed as a part
              of your CDR report. Our service goes through a series of steps-
              preparation, writing, editing and proofreading. Due to our
              brilliant services, Engineers Australia has given 99% approval to
              us. We have a team of marvelous writers, editors, engineers & EA
              assessment professionals who are always on their toes to offer the
              best assistance with CDR. With the help of their industry &
              academic experiences, our outstanding experts prepare reliable CDR
              reports, career episodes & summary statement for our customers.
              With years of experience and professional writers to provide you
              with some of the best writing services in the market, we can meet
              and exceed all your expectations. We always make it a point to
              adhere to industry standards and quality levels so that our
              write-ups are exceptionally good and can serve whatever goal it
              has been assigned with. We also are aware of the expectations of
              the clients and hence, we are always ready to go the extra mile
              crafting bespoke and client-centric service propositions that are
              both affordable and unique.
            </p>
          </div>
        </div>
        <div class="col-xl-6 col-lg-6 col-md-5 col-sm-12">
          <div class="cdrWriting_image1">
            <img
              src="./../../../assets/images/Header SEction.jpg"
              alt="thumbnail"
              class="img-thumbnail"
              style="width: 300px"
            />
          </div>
          <div class="cdrWriting_image2">
            <img
              src="./../../../assets/images/cdr_writing_services_foregrounf.png"
              class="img-fluid"
              alt="Responsive image"
            />
          </div>
        </div>
      </div>
    </div>
    <hr />
  </div>

  <div class="meet_our_experts">
    <div class="container">
      <div class="row mb-4">
        <div class="col-xl-12 col-lg-12 col-sm-12 col-xs-12 col-md-12">
          <div class="experts_title">
            <h4 class="text-center">MEET OUR <span>EXPERTS</span></h4>
          </div>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col-xl-4 col-lg-4 col-sm-12 col-xs-12 col-md-4">
          <div class="expert_column">
            <div class="expert_image mb-3">
              <img
                src="./../../../assets/images/expert_1.png"
                alt="thumbnail"
                class="img-thumbnail"
              />
            </div>
            <div class="expert_name mb-3 ml-3">
              <h4>Adam Smith Jr.</h4>
            </div>
            <div class="expert_desc ml-3">
              <h6>Lorem ipsum dolor sit amet.</h6>
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-lg-4 col-sm-12 col-xs-12 col-md-4">
          <div class="expert_column">
            <div class="expert_image mb-3">
              <img
                src="./../../../assets/images/expert_2.png"
                alt="thumbnail"
                class="img-thumbnail"
              />
            </div>
            <div class="expert_name mb-3 ml-3">
              <h4>Adam Smith Jr.</h4>
            </div>
            <div class="expert_desc ml-3">
              <h6>Lorem ipsum dolor sit amet.</h6>
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-lg-4 col-sm-12 col-xs-12 col-md-4">
          <div class="expert_column">
            <div class="expert_image mb-3">
              <img
                src="./../../../assets/images/expert_3.png"
                alt="thumbnail"
                class="img-thumbnail"
              />
            </div>
            <div class="expert_name mb-3 ml-3">
              <h4>Adam Smith Jr.</h4>
            </div>
            <div class="expert_desc ml-3">
              <h6>Lorem ipsum dolor sit amet.</h6>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-4 col-lg-4 col-sm-12 col-xs-12 col-md-4">
          <div class="expert_column">
            <div class="expert_image mb-3">
              <img
                src="./../../../assets/images/expert_4.png"
                alt="thumbnail"
                class="img-thumbnail"
              />
            </div>
            <div class="expert_name mb-3 ml-3">
              <h4>Adam Smith Jr.</h4>
            </div>
            <div class="expert_desc ml-3">
              <h6>Lorem ipsum dolor sit amet.</h6>
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-lg-4 col-sm-12 col-xs-12 col-md-4">
          <div class="expert_column">
            <div class="expert_image mb-3">
              <img
                src="./../../../assets/images/expert_5.png"
                alt="thumbnail"
                class="img-thumbnail"
              />
            </div>
            <div class="expert_name mb-3 ml-3">
              <h4>Adam Smith Jr.</h4>
            </div>
            <div class="expert_desc ml-3">
              <h6>Lorem ipsum dolor sit amet.</h6>
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-lg-4 col-sm-12 col-xs-12 col-md-4">
          <div class="expert_column">
            <div class="expert_image mb-3">
              <img
                src="./../../../assets/images/expert_6.png"
                alt="thumbnail"
                class="img-thumbnail"
              />
            </div>
            <div class="expert_name mb-3 ml-3">
              <h4>Adam Smith Jr.</h4>
            </div>
            <div class="expert_desc ml-3">
              <h6>Lorem ipsum dolor sit amet.</h6>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-5 pt-3">
        <div class="executive_words">
          <h4>
            Lorem Ipsum Dolor sit amet Set ersoicitas unde iste n este natis
            error sit attem Lorem Ipsum CDR Writing Set ersoicitas unde iste
            natis erLorem Ipsum Dolor sit amet Set ersoicitas unde iste natis
            error sit attem
          </h4>
        </div>
      </div>
      <div class="row exec">
        <div class="col-xl-3 col-lg-3 col-sm-12 col-md-6">
          <div class="executive_photo">
            <img
              src="./../../../assets/images/Rectangle 85.jpg"
              alt="thumbnail"
              class="img-thumbnail"
            />
          </div>
        </div>
        <div class="col-xl-9 col-lg-9 col-sm-12 col-md-6">
          <div class="executive_words_name pt-4">
            <h4>Ollie Murs</h4>
            <h6>CEO</h6>
          </div>
          <div class="executive_words2">
            <div class="font_icon">
              <i class="fas fa-quote-left"></i>
            </div>
            <div class="words">
              <h4>
                Lorem Ipsum Dolor sit amet Set ersoicitas unde iste natis
                erLorem Ipsum Dolor sit amet Set ersoicitas unde e iste natis
                erLorem Ipsum Dolor sit amet Set ersoicitas unde iste natis
              </h4>
            </div>
            <div class="font_icon2">
              <i class="fas fa-quote-right"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

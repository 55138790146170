import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { contactusService } from 'src/app/dashboard/services/contactus.service';
import { MsgService } from 'src/app/shared/service/msg.service';
import { contactUsForm } from '../models/contactus.model';

@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.scss']
})
export class ContactusComponent implements OnInit {
  submitting:Boolean=false;
  contactus;
  constructor(
    public router:Router,
    public msgService:MsgService,
    public contactusService:contactusService
  ) { 
    this.contactus=new contactUsForm({});
  }

  ngOnInit(): void {
  }
  submitContactUs(){
    this.submitting=true;
    this.contactusService.add(this.contactus).subscribe(
      (data:any)=>{
        this.msgService.showSuccess('Thankyou for your inquiry. We will get back to you soon.');
        this.contactus=new contactUsForm({})
        this.submitting=false;
      },error=>{
        this.msgService.showWarning('Error! Please try again later')
      }
    )
  }

}

import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { AboutusComponent } from './aboutus/aboutus.component';
import { AnzscoCodeComponent } from './anzsco-code/anzsco-code.component';
import { BlogComponent } from './blog/blog.component';
import { BlogByNameComponent } from './blog-by-name/blog-by-name.component';
import { CdrRejectionComponent } from './cdr-rejection/cdr-rejection.component';
import { ContactusComponent } from './contactus/contactus.component';
import { FaqComponent } from './faq/faq.component';
import { HomeComponent } from './home/home.component';
import { HowtoComponent } from './howto/howto.component';
import { PricingComponent } from './pricing/pricing.component';
import { SampleComponent } from './sample/sample.component';
import { SamplebynameComponent } from './samplebyname/samplebyname.component';
import { ServicesComponent } from './services/services.component';
import { ServicesbynameComponent } from './servicesbyname/servicesbyname.component';
import { TermsofuseComponent } from './termsofuse/termsofuse.component';




const generalRoute: Routes = [
    {
        path: 'about-us',
        component: AboutusComponent
    },
    {
        path: 'anzsco-code',
        component: AnzscoCodeComponent
    },
    {
        path: 'blog',
        component: BlogComponent
    },
    {
        path: 'blog/:name',
        component: BlogByNameComponent
    },
    {
        path: 'cdr-rejection',
        component: CdrRejectionComponent
    },
    {
        path: 'contact-us',
        component: ContactusComponent
    },
    {
        path: 'faq',
        component: FaqComponent
    },
    {
        path: 'home',
        component: HomeComponent
    },
    {
        path: 'how-to',
        component: HowtoComponent
    },
    {
        path: 'pricing',
        component: PricingComponent
    },
    {
        path: 'sample',
        component: SampleComponent
    },
    {
        path: 'sample/:name',
        component: SamplebynameComponent
    },
    {
        path: 'services',
        component: ServicesComponent
    },
    {
        path: 'services/:name',
        component: ServicesbynameComponent
    },
    {
        path: 'terms',
        component: TermsofuseComponent
    },





]
@NgModule({
    imports: [RouterModule.forChild(generalRoute)],
    exports: [RouterModule]
})
export class generalRouterModule {

}

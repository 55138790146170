<div class="topnav_cdr fixed-top">
  <div class="row">  
    <div class="col-xl-4 col-lg-4 col-sm-12 col-md-12" id="none">        
    </div> 
    <div class="col-xl-4 col-lg-6 col-sm-6 col-md-6">
      <div class="text-center">
        <div class="nav_head1">
          <span class="twitter_icon"><i class="fab fa-twitter"></i></span>
          <span class="twitter_icon"><i class="fab fa-facebook-f"></i></span>
          <span class="twitter_icon"><i class="fab fa-linkedin-in"></i></span>        
        </div>
      </div>
      
    </div>
    <div class="col-xl-4 col-lg-6 col-sm-6 col-md-6" >
      <div class="text-center">
        <div class="nav_head2 pl-2">
          <span class="info"><i class="far fa-envelope"></i>&nbsp; krijupatel01@gmail.com</span>
          <!-- <span class="info"><i class="fas fa-phone-volume"></i>&nbsp; +61-491264016</span> -->
        </div>
      </div>
    </div>     
  </div>
</div>
<mdb-navbar SideClass="navbar navbar-expand-lg navbar-dark intro-fixed-nav fixed-top scrolling-navbar "   [containerInside]="false"  >

  <!-- Navbar brand -->
  <mdb-navbar-brand><a class="navbar-brand logo" href="#"><img id="logo" src="./../../../assets/images/cdr logo 1.png"></a></mdb-navbar-brand>
  <links>
    
    <!-- Links -->
    <ul class="navbar-nav m-auto">
      <li class="nav-item ">
        <a class="nav-link waves-light" mdbWavesEffect [routerLink]="['/services']">Services <i class="fas fa-caret-down"></i></a>
      </li>
      <li class="nav-item" >
        <a class="nav-link waves-light" mdbWavesEffect [routerLink]="['/sample']">Request Sample</a>
      </li>
      <li class="nav-item">
        <a class="nav-link waves-light" mdbWavesEffect [routerLink]="['/about-us']">About Us</a>
      </li>
      <li class="nav-item">
        <a class="nav-link waves-light" mdbWavesEffect [routerLink]="['/how-to']">How To</a>
      </li>      
      <li class="nav-item">
        <a class="nav-link waves-light" mdbWavesEffect [routerLink]="['/pricing']">Pricing</a>
      </li>
      <li class="nav-item">
        <a class="nav-link waves-light" mdbWavesEffect [routerLink]="['/faq']">FAQ</a>
      </li>
      <li class="nav-item">
        <a class="nav-link waves-light" mdbWavesEffect [routerLink]="['/contact-us']">Contact Us</a>
      </li>
      <li class="nav-item">
        <a class="nav-link waves-light" mdbWavesEffect [routerLink]="['/anzsco-code']">ANZSCO CODE</a>
      </li>
      <li class="nav-item">
        <a class="nav-link waves-light" mdbWavesEffect [routerLink]="['/cdr-rejection']">CDR Rejection</a>
      </li>
      <li class="nav-item" *ngIf="!loggedIn">
        <button class="btn btn-primary" onclick="Chatra('openChat', true)">Buy Now</button>
      </li>
      <li class="nav-item" *ngIf="loggedIn">
        <button class="btn btn-primary" [routerLink]="['/dashboard/dashboard']" >Dashboard</button>
      </li>
    </ul>
    <!-- Links -->
  
  </links>
  
  <!-- Collapsible content -->
  
</mdb-navbar>

<!-- <div class="header">
  <div class="navbar">
    <div class="logo">
      <img src="./../../../assets/images/cdr logo.png">
    </div>
    <ul>
      <li><a href="#">Services</a></li>
      <li><a href="#">Request Sample</a></li>
      <li><a [routerLink]="['/aboutus']">About Us</a></li>
      <li><a [routerLink]="['/howto']">How to Works</a></li>
      <li><a [routerLink]="['/pricing']">Pricing</a></li>
      <li><a [routerLink]="['/faq']">FAQ</a></li>
      <li><a [routerLink]="['/contactus']">Contact Us</a></li>
    </ul>
    <button type="button" mdbBtn color="info" mdbWavesEffect>BUY NOW</button>
  </div>


</div> -->
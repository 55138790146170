import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from 'src/app/shared/models/user.model';
import { MsgService } from 'src/app/shared/service/msg.service';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-verifyaccount',
  templateUrl: './verifyaccount.component.html',
  styleUrls: ['./verifyaccount.component.scss']
})
export class VerifyaccountComponent implements OnInit {
  token;
  user;
  submitButtClick:Boolean=false;
  resendtoken:Boolean=false;
  btnclicked:Boolean=false;
 
  constructor(
    public router: Router,
    public activeRoute: ActivatedRoute,
    public msgService: MsgService,
    public authService: AuthService
  ) {
    this.token = this.activeRoute.snapshot.params['token'];
    this.user = new User({});
   }

  ngOnInit(): void {
    document.documentElement.scrollTop=0;
  }
  submit() {
    this.submitButtClick=true;
    this.user.token = this.token;
    this.authService.verifyAccount(this.user).subscribe(
      (data:any) => {
        this.msgService.showSuccess('Account Activation success');
        this.router.navigate(['/auth/login']);
      },
      (error:any) => {
        var sub=error.error;
        var sub2=sub.split('<h1>')[1].split('</h1>')[0];
        this.msgService.showError(sub2);
        this.resendtoken=true;
      }
    )
  }
  resendActivationKey(){
    this.btnclicked=true;
    this.user.token='';   
    this.authService.resetAccountActivation(this.user).subscribe(
      (data:any)=>{
        this.btnclicked=false;
        this.msgService.showSuccess('Account Activation Link send.');
        this.router.navigate(['/user/dashboard']);
      },(error:any)=>{
        var sub=error.error;
        var sub2=sub.split('<h1>')[1].split('</h1>')[0];
        this.msgService.showError(sub2);
      }
    )

  }

}

export class pricingPackagesInfo {
    name:String;
    category_id:Number;
    twentyoneDayPrice:Number;
    fourteenDayPrice:Number;
    sevenDayprice:Number;
    categoryName:String;
  
    constructor(obj:any){
        this.name=obj.name||'';
        this.category_id=obj.category_id||'';

        this.twentyoneDayPrice=obj.twentyoneDayPrice||'';
        this.fourteenDayPrice=obj.fourteenDayPrice||'';
        this.sevenDayprice=obj.sevenDayprice||'';
        this.categoryName=obj.categoryName||'';
        
    }
  }
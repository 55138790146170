<div class="sidebar">
    <app-sidebar></app-sidebar>
</div>
<div class="main">
    <div class="bg">
        <div class="row">
            <div class="col-12">
                <h1 class="text-center p-5 my-5">Welcome to Softmandu's Client Side</h1>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <form class="form-group" #addSetupsInfoForm="ngForm" validated>
              <div class="row px-5">
                <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
                    <li [ngbNavItem]="1">
                      <a ngbNavLink>Basic Settings</a>
                      <ng-template ngbNavContent>
                        <b>Company Name: </b> <input class="form-control" type="text"  [(ngModel)]="addSetups.company_name"
                            name="Company_name"  #company_nameis="ngModel" placeholder="company name">
                        <div class="danger" [hidden]="company_nameis.valid || company_nameis.pristine">
                        required field
                        </div>
                        <b>Company Vat/Pan Number: </b> <input class="form-control" type="text"  [(ngModel)]="addSetups.company_number"
                            name="Company_number"  #company_numberis="ngModel" placeholder="Pan/Vat Number">
                        <div class="danger" [hidden]="company_numberis.valid || company_numberis.pristine">
                        required field
                        </div>
                        <b>Address: </b> <input class="form-control" type="text"  [(ngModel)]="addSetups.address"
                            name="Address"  #addressis="ngModel" placeholder="Company Address">
                        <div class="danger" [hidden]="addressis.valid || addressis.pristine">
                        required field
                        </div>
                        <b>Contact Email: </b> <input class="form-control" type="email"  [(ngModel)]="addSetups.email"
                            name="email"  #emailis="ngModel" placeholder="Contact Email Address">
                        <div class="danger" [hidden]="emailis.valid || emailis.pristine">
                        required field
                        </div>
                        <b>Copyright Year: </b> <input class="form-control" type="number"  [(ngModel)]="addSetups.copyright_year"
                            name="Copyright_year"  #copyright_yearis="ngModel" placeholder="Copyright Year">
                        <div class="danger" [hidden]="copyright_yearis.valid || copyright_yearis.pristine">
                        required field
                        </div>
                        
                    </ng-template>
                    </li>
                    <li [ngbNavItem]="2">
                      <a ngbNavLink>Social Links</a>
                      <ng-template ngbNavContent>
                        <b>Facebook Link: </b> <input class="form-control" type="text"  [(ngModel)]="addSetups.facebook_link"
                            name="Facebook_link"  #facebook_linkis="ngModel" placeholder="Facebook Link">
                        <div class="danger" [hidden]="facebook_linkis.valid || facebook_linkis.pristine">
                            required field
                        </div>
                        <b>Instagram Link: </b> <input class="form-control" type="text"  [(ngModel)]="addSetups.instagram_link"
                            name="Instagram_link"  #instagram_linkis="ngModel" placeholder="Instagram Link">
                        <div class="danger" [hidden]="instagram_linkis.valid || instagram_linkis.pristine">
                            required field
                        </div>
                        <b>Twitter Link: </b> <input class="form-control" type="text"  [(ngModel)]="addSetups.twitter_link"
                            name="Twitter_link"  #twitter_linkis="ngModel" placeholder="Twitter Link">
                        <div class="danger" [hidden]="twitter_linkis.valid || twitter_linkis.pristine">
                            required field
                        </div>
                        <b>Youtube Link: </b> <input class="form-control" type="text"  [(ngModel)]="addSetups.youtube_link"
                            name="Youtube_link"  #youtube_linkis="ngModel" placeholder="Twitter Link">
                        <div class="danger" [hidden]="youtube_linkis.valid || youtube_linkis.pristine">
                            required field
                        </div>
                       
                      </ng-template>
                    </li>
                    <li [ngbNavItem]="3">
                      <a ngbNavLink>Terms and Policies</a>
                      <ng-template ngbNavContent>
                        <h4><b>Terms : </b></h4> 
                        <quill-editor [(ngModel)]="addSetups.terms" 
                                  placeholder="Terms"
                                  [styles]="{minHeight: '200px'}"
                                  [modules]="quillConfig"
                                  (onSelectionChanged)="onSelectionChanged($event)"
                                  (onContentChanged)="onContentChanged($event)"
                                  name="Terms"  #termsis="ngModel" >
                        </quill-editor>
                        <h4 class="mt-2"><b>Policy : </b></h4> 
                        <quill-editor [(ngModel)]="addSetups.policies" 
                                  placeholder="Policy"     
                                  [styles]="{minHeight: '200px'}"                   
                                  [modules]="quillConfig"
                                  (onSelectionChanged)="onSelectionChanged($event)"
                                  (onContentChanged)="onContentChanged($event)"
                                  name="Policies"  #policiesis="ngModel" >
                        </quill-editor>
                       
                       
                        <h4 class="mt-2"><b>Copyright Notice : </b></h4> 
                        <quill-editor [(ngModel)]="addSetups.copyrightNotice" 
                                  placeholder="Copyright Notice"     
                                  [styles]="{minHeight: '200px'}"                   
                                  [modules]="quillConfig"
                                  (onSelectionChanged)="onSelectionChanged($event)"
                                  (onContentChanged)="onContentChanged($event)"
                                  name="CopyrightNotice"  #copyrightNoticeis="ngModel" >
                        </quill-editor>
                        <h4 class="mt-2"><b>Cookie Policy : </b></h4> 
                        <quill-editor [(ngModel)]="addSetups.cookiePolicy" 
                                  placeholder="Cookie Policy"     
                                  [styles]="{minHeight: '200px'}"                   
                                  [modules]="quillConfig"
                                  (onSelectionChanged)="onSelectionChanged($event)"
                                  (onContentChanged)="onContentChanged($event)"
                                  name="cookiePolicy"  #cookiePolicyis="ngModel" >
                        </quill-editor>
                      </ng-template>
                    </li>
                    <li [ngbNavItem]="4">
                        <a ngbNavLink>About Us</a>
                        <ng-template ngbNavContent>
                        <h4 class="mt-2"><b>Brief About-Us : </b></h4> 
                        <quill-editor [(ngModel)]="addSetups.brief_about_us" 
                                  placeholder="Breaf About Us"     
                                  [styles]="{minHeight: '200px'}"                   
                                  [modules]="quillConfig"
                                  (onSelectionChanged)="onSelectionChanged($event)"
                                  (onContentChanged)="onContentChanged($event)"
                                  name="Brief_about_us"  #brief_about_usis="ngModel" >
                        </quill-editor>
                        <h4 class="mt-2"><b>Detail About-Us : </b></h4> 
                        <quill-editor [(ngModel)]="addSetups.detail_about_us" 
                                  placeholder="Detail About Us"     
                                  [styles]="{minHeight: '200px'}"                   
                                  [modules]="quillConfig"
                                  (onSelectionChanged)="onSelectionChanged($event)"
                                  (onContentChanged)="onContentChanged($event)"
                                  name="Detail_about_us"  #detail_about_usis="ngModel" >
                        </quill-editor>
                       
                        
                      </ng-template>
                      </li>
                    <li [ngbNavItem]="5">
                        <a ngbNavLink>Seos</a>
                        <ng-template ngbNavContent>
                          <b>Meta keywords: </b> <input class="form-control" type="text"  [(ngModel)]="addSetups.meta_keywords"
                            name="Meta_keywords"  #meta_keywordsis="ngModel" placeholder="Meta Keywords">
                          <div class="danger" [hidden]="meta_keywordsis.valid || meta_keywordsis.pristine">
                          required field
                          </div>
                          <b>Meta Description: </b> <textarea class="form-control" type="text"  [(ngModel)]="addSetups.meta_description"
                            name="meta_description"  #meta_descriptionis="ngModel" placeholder="Meta Description"></textarea>
                          <div class="danger" [hidden]="meta_descriptionis.valid || meta_descriptionis.pristine">
                          required field
                          </div>
                          <b>Site Title: </b> <input class="form-control" type="text"  [(ngModel)]="addSetups.site_title"
                            name="Site_title"  #site_titleis="ngModel" placeholder="Site Title">
                          <div class="danger" [hidden]="site_titleis.valid || site_titleis.pristine">
                          required field
                          </div>
                          <b>Site Description: </b> <textarea class="form-control" type="text"  [(ngModel)]="addSetups.site_description"
                            name="Site_description"  #site_descriptionis="ngModel" placeholder="Site Description"></textarea>
                          <div class="danger" [hidden]="site_descriptionis.valid || site_descriptionis.pristine">
                          required field
                          </div>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="6">
                        <a ngbNavLink>Image</a>
                        <ng-template ngbNavContent>
                            <h4>Current Image</h4>
                            <div class="row">                            
                                <img src="{{imageUrl}}{{addSetups.logo}}" style="height: 200px; width: 300px;">
                            </div>
                            <div class="row">
                                <div class="pp my-3" *ngIf="!imageSelected">
                                    <label for="file-upload" class="custom-file-upload">
                                        <i class="fa fa-cloud-upload"></i> Click here to Upload Image <i class="fa fa-chevron-right" aria-hidden="true"></i>
                                    </label>
                                    <input id="file-upload" type="file"
                                        accept=".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*"
                                        (change)="fileChanged($event)" multiple />
                                </div>
                            </div>
                            <div class="row" *ngIf="imageSelected">
                                
                                    <div class="col-sm-4 col-md-4 col-lg-4 col-xl-4">
                                        <button class="btn btn-success" disabled>{{fileno}} Images</button>
                                    </div>
                                    <div class="col-sm-4 col-md-4 col-lg-4 col-xl-4">
                                        <button class="btn btn-danger" (click)="removeUploadedFile()">Remove All!</button>
                                    </div>
                                
                            </div>
                            <div class="row">
                                <div class="pp text-center" *ngIf="imageSelected">
                                    <label for="file-upload" class="custom-file-upload">
                                        <i class="fa fa-cloud-upload"></i>Click Here To Change All Images
                                    </label>
                                    <input id="file-upload" type="file" (change)="fileChanged($event)" multiple />
                                </div>
                            </div>
                            <div class="row" *ngIf="imageSelected">
                                <h2 class="text-success"><strong>New Uploaded Image</strong></h2>
                            </div>                    
                            <div class="row">
                                <div class="col-sm-4 col-md-4 col-lg-4 col-xl-4" *ngFor="let url of urls;let i=index" id="ram"
                                    style="width:400px">
                                    <img [src]="url" alt="thumbnail" class="img-thumbnail" style="width: 300px;height: 300px;">
                                    <i class="fas fa-trash-alt" id="deleteIcon" (click)="removeNew(i)"></i>
                                </div>
                            </div>
                        </ng-template>
                    </li>
                  </ul>
              </div>          
          </form>
        </div>
      </div>
      <div class="row px-5">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div [ngbNavOutlet]="nav" class="mt-2"></div>
        </div>
        
      </div>
        <div class="row mt-4 px-5">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <button class="btn btn-success" (click)="submit()" >Submit</button>
            </div>        
        </div>
</div>
import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { ActivateaccountComponent } from './activateaccount/activateaccount.component';
import { ForgotpasswordComponent } from './forgotpassword/forgotpassword.component';
import { LoginComponent } from './login/login.component';
import { ResetpasswordComponent } from './resetpassword/resetpassword.component';
import { VerifyaccountComponent } from './verifyaccount/verifyaccount.component';



const authRoute: Routes = [
    {
        path: 'activate-account/:token',
        component: ActivateaccountComponent
    },
    {
        path: 'forgot-password',
        component: ForgotpasswordComponent
    },
    {
        path: 'login',
        component: LoginComponent
    },
    {
        path: 'reset-password/:token',
        component: ResetpasswordComponent
    },
    {
        path: 'verify-account/:token',
        component: VerifyaccountComponent
    },
]
@NgModule({
    imports: [RouterModule.forChild(authRoute)],
    exports: [RouterModule]
})
export class authenticationRouterModule {

}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './../../shared/service/baseService';
import { Observable } from 'rxjs';
import { pricingPackagesInfo } from '../models for dashboard/pricingpackages.model';


@Injectable()
export class pricingPackageService extends BaseService {
    url:any;
    constructor(public http: HttpClient) {
        super();
    }
    add(data: pricingPackagesInfo) {
        return this.http.post(this.baseUrl + 'pricingpackages', data, this.getOptionsWithToken());
    }
    update(data: any) {
        return this.http.put(this.baseUrl + 'pricingpackages/' + data._id, data, this.getOptionsWithToken());
    }
    getall() {
        return this.http.get(this.baseUrl + 'pricingpackages/myOwnData', this.getOptionsWithToken());
    }
    getByDate(date:any) {
        return this.http.get(this.baseUrl + 'pricingpackages/myOwnData/date/' + date, this.getOptionsWithToken());
    }

    remove(id:any) {
        return this.http.delete(this.baseUrl + 'pricingpackages/' + id, this.getOptionsWithToken());
    }
    getById(id:any) {
        return this.http.get(this.baseUrl + 'pricingpackages/' + id, this.getOptionsWithToken());
    }
    filterByYear(data:any) {
        return this.http.post(this.baseUrl + 'faq/filterByYear/', data, this.getOptionsWithToken());
    }

    upload(data: any, files:any) {
        const formdata = new FormData();
        const xhr = new XMLHttpRequest();
        if (files && files[0]) {
            formdata.append('img', files[0], files[0].name);
        }
        for (let key in data) {
            formdata.append(key, data[key]);
        }
        let upload = new Observable((observer) => {
            xhr.onreadystatechange = () => {
                if (xhr.readyState == 4) {
                    if (xhr.status == 200) {
                        observer.next(xhr.response);
                        console.log('everything ok,  upload successful');
                    } else {
                        console.log('error in upload', xhr.response);
                        observer.error(xhr.response);
                    }
                }
            }
        })
        let URL;
        let method;
        if (data._id) {
            URL = `${this.baseUrl}pricingpackages/${data._id}?token=${localStorage.getItem('token')}`
            method = "PUT"
        } else {
            URL = `${this.baseUrl}pricingpackages?token=${localStorage.getItem('token')}`
            method = "POST"

        }
        xhr.open(method, URL, true);
        xhr.send(formdata);
        return upload;
    }
}
import { Component, OnInit,ViewChild, HostListener, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { MdbTablePaginationComponent, MdbTableDirective } from 'angular-bootstrap-md';
import { MsgService } from 'src/app/shared/service/msg.service';

import { environment } from 'src/environments/environment';
import { faqInfo } from '../models for dashboard/faq.model';
import { testimonialsInfo } from '../models for dashboard/testimonials.model';
import { faqService } from '../services/faq.service';
import { testimonialsService } from '../services/testimonials.service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit, AfterViewInit {

  @ViewChild(MdbTablePaginationComponent, { static: true }) mdbTablePagination: MdbTablePaginationComponent;
  @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective
  elements: any = [];
  previous: any = [];
  headElements = ['S.No.', 'Question','Answer'];

  ads=[];
  dataReceived:Boolean=false;
  deleteAsk:Boolean=false;
  //for temporaray
 
  deleteid:any;

  addBackgroundShow:Boolean=false;
  addfaqs;
  submitting: Boolean;
 
  
  filesToUpload:any = [];
  
  constructor(
    private cdRef: ChangeDetectorRef,
    public msgService:MsgService,
    public faqService:faqService
    ) {
      
      this.addfaqs=new faqInfo({});
      this.submitting = false;

     }

  ngOnInit() {

        this.mdbTable.setDataSource(this.elements);
    this.elements = this.mdbTable.getDataSource();
    this.previous = this.mdbTable.getDataSource();
    this.getBrands();
  }

  ngAfterViewInit() {
    this.mdbTablePagination.setMaxVisibleItemsNumberTo(20);

    this.mdbTablePagination.calculateFirstItemIndex();
    this.mdbTablePagination.calculateLastItemIndex();
    this.cdRef.detectChanges();
  }
  getBrands(){
    this.elements=[];

    this.faqService.getall().subscribe(
      (data:any)=>{
        console.log('the data >>>>', data)
        this.msgService.showSuccess('Data Loading Success!!!');
        if(data.length !=0){
          this.dataReceived=true;
          // this.addAds.id=parseInt(data[0].id)+1;
          this.elements=data;
          this.mdbTable.setDataSource(this.elements);
          this.elements = this.mdbTable.getDataSource();
          this.previous = this.mdbTable.getDataSource();
          
        }else{
          this.dataReceived=false;
          
        }
      },
      error=>{
        this.msgService.showWarning('Data loading error !!!')
      }
    )
  }
  askDelete(i:any, data:any){
    this.deleteAsk=i;
    this.deleteid=data._id;
  }
  deleteModalClose(){
    this.deleteAsk=false;    
    this.deleteid=null;
  }
  
  delete(){
    this.faqService.remove(this.deleteid).subscribe(
      (data:any)=>{
        
        this.msgService.showSuccess('Data deleted successfully');
       
        this.getBrands();
        this.deleteAsk=false;    
      },
      error=>{
        this.msgService.showWarning('Error deleting data')
      }
    )
  }
 

  showAddCategoriesModal(){
    this.addBackgroundShow=true;
  }
  addCategoriesModalClose(){
    this.addBackgroundShow=false;
  }
  
  submit() {
    this.submitting=true;
    console.log('teh submited data is>>>', this.addfaqs)

    this.faqService.add(this.addfaqs).subscribe(
      (data:any)=>{
        this.msgService.showSuccess('Testimonials uploaded successfully');
       this.filter();        

      }, error=>{
        console.log(error)
        this.msgService.showWarning('Testimonials Upload Error');
        this.filter();
        
      }
    )
    

  }
  filter(){
    this.addBackgroundShow=false;
    this.submitting=false;
    this.filesToUpload=[];
    this.addfaqs=new faqInfo({});
   
    this.getBrands();
  }

 

}

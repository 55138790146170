<div *ngIf="addBackgroundShow">
    <div class="modal fade in" id="myModal" data-toggle="modal" style="display: block;">
        <div class="modal-dialog author-dialog">
          <div class="modal-content">
          
            <!-- Modal Header -->
            <div class="modal-header">
              <h5 class="modal-title text-danger">Add Special Pricing</h5>
              <button type="button" class="close" data-dismiss="modal" (click)="addCategoriesModalClose()">&times;</button>
            </div>
            
            <!-- Modal body -->
            <div class="modal-body">
                <form class="form-group " #addBackgroundForm="ngForm">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <h6 class="text-primary">Name: <span class="text-danger">(*)</span></h6>
                            <input class="form-control" type="text" placeholder="Title" [(ngModel)]="pricingCategories.name"
                                name="Name" required #nameis="ngModel">
                            <div class="danger" [hidden]="nameis.valid || nameis.pristine">
                                required field
                            </div> 
                            <h6 class="text-primary">Amount: <span class="text-danger">(*)</span></h6>
                            <input class="form-control" type="number" placeholder="Amount" [(ngModel)]="pricingCategories.amount"
                                name="Amount" required #amountis="ngModel">
                            <div class="danger" [hidden]="amountis.valid || amountis.pristine">
                                required field
                            </div> 
                            <h6 class="text-primary">Description: <span class="text-danger">(*)</span></h6>
                            <textarea class="form-control" type="text" placeholder="Description" [(ngModel)]="pricingCategories.description"
                                name="description" required #descriptionis="ngModel"></textarea>
                            <div class="danger" [hidden]="descriptionis.valid || descriptionis.pristine">
                                required field
                            </div> 
                            
                        </div>
                           
                                                          
                    </div>
                    
                
                <div class="row mt-3">
                    <div class="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                        <div class="m-auto">
                            <button class="btn btn-success" (click)="submit()" *ngIf="!submitting"
                                [disabled]="addBackgroundForm.form.invalid">Submit</button>
                            <button disabled class="btn btn-info" *ngIf="submitting">Submitting...</button>
                        </div>
                    </div>
                </div>
                
                </form>
            </div>
            
            <!-- Modal footer -->
            <div class="modal-footer">
              <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="addCategoriesModalClose()">Close</button>
            </div>
            
          </div>
        </div>
      </div>
</div>

<div class="sidebar">
  <app-sidebar></app-sidebar>
</div>
<div class="main">
    <div class="bg">
        <div class="row">
            <div class="col-12">
                <h1 class="text-center p-5 my-5">Welcome to Client Side</h1>
            </div>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
            <div class="heading-text ">
                <h4 >Special Pricing</h4>
            </div>
        </div>
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
            <button class="btn btn-danger" (click)="showAddCategoriesModal()" >Add Special Pricing</button>
        </div>
    </div>
    <div class="row mt-3">
      <div class="container">
          <table mdbTable #tableEl="mdbTable" class="z-depth-1">
            <thead>
            <tr>
              <th *ngFor="let head of headElements; let i = index" scope="col">{{head}}
              </th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let el of elements; let i = index">
              <th *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex"
                  scope="row">{{i+1}}</th>
              <td *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex">{{el.name}}</td>
              <td *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex">{{el.amount}}</td>
              <td *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex">{{el.description}}</td>
              <td *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex">{{el.ismain}}</td>
              <td *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex"><i class="fa fa-eye" aria-hidden="true" (click)="edit(true, el)"></i></td>

              
            </tr>
            </tbody>
            <tfoot class="grey lighten-5 w-100">
            <tr>
              <td colspan="4">
                <mdb-table-pagination [tableEl]="tableEl" [searchDataSource]="elements"></mdb-table-pagination>
              </td>
            </tr>
            </tfoot>
          </table>
        </div>
  </div>
</div>
export class faqInfo {
    question:String;
    answer:String;
    user:String;
    userId:String;
    
    
    
  
    constructor(obj:any){
        this.question=obj.question||'';
        this.answer=obj.answer||'';

        this.user=obj.user||'';
        this.userId=obj.userId||'';
       
        
    }
  }

<!-- <div *ngIf="loggedIn" >
  
    
    <router-outlet></router-outlet>
</div>
<div *ngIf="!loggedIn">
    
</div> -->
<app-header></app-header>
<router-outlet></router-outlet>
<app-footer></app-footer>



import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './authentication/services/auth.service';
import { MsgService } from './shared/service/msg.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'cdrwritingservice-project';

  user:String;
  loggedIn:boolean=false;
  logIn;

  constructor(
    public authService:AuthService,
    public msgService:MsgService,
    public router:Router
  ){
    
    this.user=JSON.parse(localStorage.getItem('user')||'{}');
    this.logIn=localStorage.getItem('loggedInUser');
    if(this.user!=null){
      if(this.logIn==='true'){
        this.loggedIn=true;
      }else{
        this.loggedIn=false;
      }
    }else{
      this.loggedIn=false;
    }
  }
  ngDoCheck(){
    this.user=JSON.parse(localStorage.getItem('user')||'{}');
    this.logIn=localStorage.getItem('loggedInUser');
    if(this.user!=null){
      if(this.logIn==='true'){
        this.loggedIn=true;
      }else{
        this.loggedIn=false;
      }
    }else{
      this.loggedIn=false;
    }
  }
}

<div class="home_content5 pricing_margin">
  <div class="great_deals1">
    <div class="row">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
        <div class="text-center">
          <h4 class="mb-3">FIND RIGHT <span>SERVICE</span> PLAN</h4>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-xl-2 col-lg-2 col-md-12 col-sm-12" id="hide"></div>
        <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12">
          <div class="text-center">          
            <h6 class="mb-3" >We have the best value for money pricing on all your writings, pricing starting only at $299. </h6>
            <button class="great_deals_butt" [routerLink]="['/pricing']">View Details</button>
          </div>
        </div>
        <div class="col-xl-2 col-lg-2 col-md-12 col-sm-12" id="hide"></div>
      </div>
    </div>  
    <div class="packages mt-5">
      <div class="container">
        <div class="row">
          <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12" id="change_padding">
            <div class="package1">
              <div class="package1_head px-5 pt-3">
                  <h4 class="text-center">Standard CDR Report Package</h4>
              </div>
              <div class="package1_price mb-3">
                <h4 class="text-center"><span><sup>$</sup></span>299</h4>
              </div>
              <div class="package1_title mb-4">
                  <h5 class="text-center">What you will receive</h5>
              </div>
              <div class="package1_description mb-5 px-4">
                  <h6>lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem psumlorem ipsum dolor sit amet lorem ipsum 
                    dolor sit amet lorem ipsum</h6>
              </div>
              <div class="text-center mt-5 pt-4">
                  <button class="pack_button" onclick="Chatra('openChat', true)">Order Now</button>
              </div>
            </div>
          </div>
          <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12" id="change_padding">
            <div class="package2">
              <div class="package2_head pt-3">
                <h4 class="text-center">SPECIAL DISCOUNT</h4>
              </div>
              <div class="package1_head px-5 pt-3">
                <h4 class="text-center">Complete CDR Report Package</h4>
              </div>
              <div class="package1_price mb-2">
                <h4 class="text-center"><span><sup>$</sup></span>899</h4>
              </div>
              <div class="package1_title mb-4 ml-3">
                <h5 class="text-center">What you will receive</h5>
              </div>
              <div class="package2_description mb-5 px-3 ml-4">
                <h6><i class="far fa-check-circle"></i>&nbsp; lorem ipsum dolor sit </h6>
                <h6><i class="far fa-check-circle"></i>&nbsp; amet lorem ipsum dolor sit </h6>
                <h6><i class="far fa-check-circle"></i>&nbsp;amet lorem psumlorem ipsum dolor </h6>
                <h6><i class="far fa-check-circle"></i>&nbsp;sit amet lorem ipsum dolor </h6>
                <h6><i class="far fa-check-circle"></i>&nbsp;sit amet lorem ipsum</h6>
              </div>
              <div class="text-center mt-5 pt-4">
                <button class="pack2_button" onclick="Chatra('openChat', true)">Order Now</button>
              </div>
            </div>
          </div>
          <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12" id="change_padding">
            <div class="package3">
                <div class="package1_head px-5 pt-3">
                <h4 class="text-center">Standard CDR Report Package</h4>
                </div>  
                <div class="package1_price mb-2">
                  <h4 class="text-center"><span><sup>$</sup></span>599</h4>
                </div>
                <div class="package1_title mb-4 ml-3">
                  <h5 class="text-center">What you will receive</h5>
                </div>
                <div class="package1_description mb-5 px-3 ml-4">
                    <h6>lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem psumlorem ipsum dolor sit amet lorem ipsum 
                      dolor sit amet lorem ipsum</h6>
                </div>
                <div class="text-center mt-5 pt-4">
                    <button class="pack_button" onclick="Chatra('openChat', true)">Order Now</button>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>  
  </div>
</div>
<div class="pricing_table mt-5">
  <div class="container">
    <div class="row pricing_table_title">
      <div class="col-xl-12 col-lg-12 col-md-12 col-xs-12">
        <h4 class="text-center">
          Need different CDR packages ? We have plans for more.
        </h4>
      </div>
    </div>
    <div class="table-responsive  mt-5">
      <table class="table">
        <thead>
          <tr>
            <th *ngFor="let head of headElements" scope="col">{{ head }}</th>
          </tr>
        </thead>
        <tbody>
          <tr mdbTableCol *ngFor="let el of elements" class="py-3">
            <td scope="row font-20">
              <h4>{{ el.title }}</h4>
            </td>
            <td class="font-20">
              <h4>
                <del class="delete_text">{{ el.fourweekCutOff }}</del
                >{{ el.fourweekprice }}
              </h4>
              <button class="pricing_table_button" onclick="Chatra('openChat', true)">Order Now</button>
            </td>
            <td class="font-20">
              <h4>
                <del class="delete_text">{{ el.twoweekCutOff }}</del
                ><span class="theme-color">{{ el.twoweekprice }}</span>
              </h4>
              <button class="pricing_table_button" onclick="Chatra('openChat', true)">Order Now</button>
            </td>
            <td class="font-20">
              <h4>
                <del class="delete_text">{{ el.oneweekCutOff }}</del
                  ><span >{{ el.oneWeekPrice }}</span>
              </h4>
              <button class="pricing_table_button" onclick="Chatra('openChat', true)">Order Now</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="row pricing_table_title mt-5">
      <div class="col-xl-12 col-lg-12 col-md-12 col-xs-12">
        <h4 class="text-center">CDR Review Packages and Plans</h4>
      </div>
    </div>
    <div class="table-responsive mt-5">
      <table class="table">
        <thead>
          <tr>
            <th *ngFor="let head of headElements" scope="col">{{ head }}</th>
          </tr>
        </thead>
        <tbody>
          <tr mdbTableCol *ngFor="let el of elements" class="py-3">
            <td scope="row font-20">
              <h4>{{ el.title }}</h4>
            </td>
            <td class="font-20">
              <h4>
                <del class="delete_text">{{ el.fourweekCutOff }}</del
                >{{ el.fourweekprice }}
              </h4>
              <button class="pricing_table_button" onclick="Chatra('openChat', true)">Order Now</button>
            </td>
            <td class="font-20">
              <h4>
                <del class="delete_text">{{ el.twoweekCutOff }}</del
                ><span class="theme-color">{{ el.twoweekprice }}</span>
              </h4>
              <button class="pricing_table_button" onclick="Chatra('openChat', true)">Order Now</button>
            </td>
            <td class="font-20">
              <h4>
                <del class="delete_text">{{ el.oneWeekCutOff }}</del
                >{{ el.oneWeekPrice }}
              </h4>
              <button class="pricing_table_button" onclick="Chatra('openChat', true)">Order Now</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- <div class="row pricing_table_title mt-5">
      <div class="col-xl-12 col-lg-12 col-md-12 col-xs-12">
        <h4 class="text-center">Membership Plans</h4>
      </div>
    </div>
    <div class="row mt-5">
      <table mdbTable>
        <thead>
          <tr>
            <th *ngFor="let head of headElements" scope="col">{{ head }}</th>
          </tr>
        </thead>
        <tbody>
          <tr mdbTableCol *ngFor="let el of elements" class="py-3">
            <td scope="row font-20">
              <h4>{{ el.title }}</h4>
            </td>
            <td class="font-20">
              <h4>
                <del class="delete_text">{{ el.fourweekCutOff }}</del
                >{{ el.fourweekprice }}
              </h4>
              <button class="pricing_table_button">Order Now</button>
            </td>
            <td class="font-20">
              <h4>
                <del class="delete_text">{{ el.twoweekCutOff }}</del
                ><span class="theme-color">{{ el.twoweekprice }}</span>
              </h4>
              <button class="pricing_table_button">Order Now</button>
            </td>
            <td class="font-20">
              <h4>
                <del class="delete_text">{{ el.oneWeekCutOff }}</del
                >{{ el.oneWeekPrice }}
              </h4>
              <button class="pricing_table_button">Order Now</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row pricing_table_title mt-5">
      <div class="col-xl-12 col-lg-12 col-md-12 col-xs-12">
        <h4 class="text-center">Other Packages</h4>
      </div>
    </div>
    <div class="row mt-5 mb-5">
      <table mdbTable>
        <thead>
          <tr>
            <th *ngFor="let head of headElements" scope="col">{{ head }}</th>
          </tr>
        </thead>
        <tbody>
          <tr mdbTableCol *ngFor="let el of elements" class="py-3">
            <td scope="row font-20">
              <h4>{{ el.title }}</h4>
            </td>
            <td class="font-20">
              <h4>
                <del class="delete_text">{{ el.fourweekCutOff }}</del
                >{{ el.fourweekprice }}
              </h4>
              <button class="pricing_table_button">Order Now</button>
            </td>
            <td class="font-20">
              <h4>
                <del class="delete_text">{{ el.twoweekCutOff }}</del
                ><span class="theme-color">{{ el.twoweekprice }}</span>
              </h4>
              <button class="pricing_table_button">Order Now</button>
            </td>
            <td class="font-20">
              <h4>
                <del class="delete_text">{{ el.oneWeekCutOff }}</del
                >{{ el.oneWeekPrice }}
              </h4>
              <button class="pricing_table_button">Order Now</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div> -->
  </div>
</div>

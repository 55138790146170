import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cdr-rejection',
  templateUrl: './cdr-rejection.component.html',
  styleUrls: ['./cdr-rejection.component.scss']
})
export class CdrRejectionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

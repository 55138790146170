<div class="sidebar">
    <a  color="primary" class="waves-light" (click)="test1.toggle()" mdbWavesEffect aria-expanded="false" role="button"><i class="fa fa-fw fa-home"></i> Home</a> 
      <div class="" mdbCollapse #test1="bs-collapse">
        <a id="sidebar_for_a" [routerLink]="['/dashboard/dashboard']">Dashboard</a>        
      </div>
   
     
      <a  color="primary" class="waves-light" (click)="test2.toggle()" mdbWavesEffect aria-expanded="false" role="button"><i class="fas fa-book-open"></i> Pricing</a> 
      <div class="" mdbCollapse #test2="bs-collapse">
        <a id="sidebar_for_a" [routerLink]="['/dashboard/pricing-packages']">Add Pricing</a>   
        <a id="sidebar_for_a" [routerLink]="['/dashboard/pricing-categories']">Add Pricing Categories</a>
        <a id="sidebar_for_a" [routerLink]="['/dashboard/special-pricing']">Add Special Pricing</a>
       
      </div>
      <a  color="primary" class="waves-light" (click)="test3.toggle()" mdbWavesEffect aria-expanded="false" role="button"><i class="fas fa-money-check"></i> Experts</a> 
      <div class="" mdbCollapse #test3="bs-collapse">        
        <a id="sidebar_for_a" [routerLink]="['/dashboard/expert']">View Experts</a> 
      </div>
      <a  color="primary" class="waves-light" (click)="test4.toggle()" mdbWavesEffect aria-expanded="false" role="button"><i class="fas fa-money-check"></i> Testimonials</a> 
      <div class="" mdbCollapse #test4="bs-collapse">        
        <a id="sidebar_for_a" [routerLink]="['/dashboard/addTestimonials']">View Testimonials</a> 
      </div>
      <a  color="primary" class="waves-light" (click)="test5.toggle()" mdbWavesEffect aria-expanded="false" role="button"><i class="fas fa-money-check"></i> Consultation</a> 
      <div class="" mdbCollapse #test5="bs-collapse">        
        <a id="sidebar_for_a" [routerLink]="['/dashboard/consultation']">View Consultation</a> 
      </div>
      <a  color="primary" class="waves-light" (click)="test6.toggle()" mdbWavesEffect aria-expanded="false" role="button"><i class="fas fa-file-invoice"></i> FAQ</a> 
      <div class="" mdbCollapse #test6="bs-collapse">
        <a id="sidebar_for_a" [routerLink]="['/dashboard/Addfaq']">Add FAQ</a>      
      </div>
    <a  color="primary" class="waves-light" (click)="test7.toggle()" mdbWavesEffect><i class="fas fa-users-cog"></i> Inquiry</a> 
      <div class="" mdbCollapse #test7="bs-collapse">
        <a id="sidebar_for_a" [routerLink]="['/dashboard/inquiry']">View Inquiry</a> 
      </div>    
      <a  color="primary" class="waves-light" (click)="test8.toggle()" mdbWavesEffect><i class="fas fa-users-cog"></i> Assistance</a> 
      <div class="" mdbCollapse #test8="bs-collapse">
        <a id="sidebar_for_a" [routerLink]="['/dashboard/assistance']">View Assistance</a> 
      </div>  
      <a  color="primary" class="waves-light" (click)="test9.toggle()" mdbWavesEffect><i class="fas fa-users-cog"></i> User Question</a> 
      <div class="" mdbCollapse #test9="bs-collapse">
        <a id="sidebar_for_a" [routerLink]="['/dashboard/userQuestion']">View User Question</a> 
      </div>  
      <a  color="primary" class="waves-light" (click)="test10.toggle()" mdbWavesEffect><i class="fas fa-users-cog"></i> Blog</a> 
      <div class="" mdbCollapse #test10="bs-collapse">
        <a id="sidebar_for_a" [routerLink]="['/dashboard/addBlog']">Add Blog</a> 
        <a id="sidebar_for_a" [routerLink]="['/dashboard/list-blog']">View Blog</a> 
      </div> 
      <a  color="primary" class="waves-light" (click)="test11.toggle()" mdbWavesEffect><i class="fas fa-users-cog"></i> Sample Req</a> 
      <div class="" mdbCollapse #test11="bs-collapse">
        <a id="sidebar_for_a" [routerLink]="['/dashboard/listSample-req']">View Sample Req</a> 
      </div>  
      <a  color="primary" class="waves-light" (click)="test12.toggle()" mdbWavesEffect><i class="fas fa-users-cog"></i> Setting</a> 
      <div class="" mdbCollapse #test12="bs-collapse">
        <a id="sidebar_for_a" [routerLink]="['/dashboard/pageInfo']">View Page Info</a> 
        <a id="sidebar_for_a" [routerLink]="['/dashboard/setting']">Settings</a> 
      </div>              
      <a  color="primary" class="waves-light" mdbWavesEffect (click)="logout()" ><i class="fas fa-sign-out-alt" ></i> Log Out</a> 
  </div>
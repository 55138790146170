<div class="sidebar">
    <app-sidebar></app-sidebar>
</div>
<div class="main">
    <div class="bg">
        <div class="row">
            <div class="col-12">
                <h1 class="text-center p-5 my-5">Welcome to  Client Side</h1>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <form class="form-group" #addSetupsInfoForm="ngForm" validated>
              <div class="row px-5">
                <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
                    <li [ngbNavItem]="1">
                      <a ngbNavLink>Basic Information</a>
                      <ng-template ngbNavContent>
                        <b>Name: </b> <input class="form-control" type="text"  [(ngModel)]="addBlogInfo.name"
                            name="Name"  #nameis="ngModel" placeholder="Name">
                        <div class="danger" [hidden]="nameis.valid || nameis.pristine">
                        required field
                        </div>
                        <b>Title: </b> <input class="form-control" type="text"  [(ngModel)]="addBlogInfo.title"
                            name="Title"  #titleis="ngModel" placeholder="Title">
                        <div class="danger" [hidden]="titleis.valid || titleis.pristine">
                        required field
                        </div>
                       
                        <b>Date: </b> <input class="form-control" type="date"  [(ngModel)]="addBlogInfo.date"
                            name="Date"  #dateis="ngModel" placeholder="Date">
                        <div class="danger" [hidden]="dateis.valid || dateis.pristine">
                        required field
                        </div>
                        <b>Author Name: </b> <input class="form-control" type="text"  [(ngModel)]="addBlogInfo.author_id"
                        name="Author_id"  #author_idis="ngModel" placeholder="Author">
                    <div class="danger" [hidden]="author_idis.valid || author_idis.pristine">
                    required field
                    </div>
                        
                    </ng-template>
                    </li>
                    <li [ngbNavItem]="2">
                      <a ngbNavLink>Details</a>
                      <ng-template ngbNavContent>
                        <h4><b>Description 1 : </b></h4> 
                        <quill-editor [(ngModel)]="addBlogInfo.description" 
                                  placeholder="Terms"
                                  [styles]="{minHeight: '200px'}"
                                  [modules]="quillConfig"
                                  (onSelectionChanged)="onSelectionChanged($event)"
                                  (onContentChanged)="onContentChanged($event)"
                                  name="Description"  #descriptionis="ngModel" >
                        </quill-editor>
                        <h4 class="mt-2"><b>Description 2 : </b></h4> 
                        <quill-editor [(ngModel)]="addBlogInfo.description2" 
                                  placeholder="Description second"     
                                  [styles]="{minHeight: '200px'}"                   
                                  [modules]="quillConfig"
                                  (onSelectionChanged)="onSelectionChanged($event)"
                                  (onContentChanged)="onContentChanged($event)"
                                  name="Description2"  #description2is="ngModel" >
                        </quill-editor>
                       
                      </ng-template>
                    </li>
                   
                    <li [ngbNavItem]="3">
                        <a ngbNavLink>Seos</a>
                        <ng-template ngbNavContent>                          
                          <b>Seo Keyword : </b> <input class="form-control" type="text"  [(ngModel)]="addBlogInfo.seo_keyword"
                            name="seo_keyword"  #seo_keywordis="ngModel" placeholder="Seo Keyword">
                          
                          <b>SEO Description: </b> <textarea class="form-control" type="text"  [(ngModel)]="addBlogInfo.seo_description"
                            name="seo_description"  #seo_descriptionis="ngModel" placeholder="SEO Description"></textarea>
                          <div class="danger" [hidden]="seo_descriptionis.valid || seo_descriptionis.pristine">
                          required field
                          </div>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="4">
                        <a ngbNavLink>Image</a>
                        <ng-template ngbNavContent>
                            
                            <div class="row">
                                <div class="pp my-3" *ngIf="!imageSelected">
                                    <label for="file-upload" class="custom-file-upload">
                                        <i class="fa fa-cloud-upload"></i> Click here to Upload Image <i class="fa fa-chevron-right" aria-hidden="true"></i>
                                    </label>
                                    <input id="file-upload" type="file"
                                        accept=".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*"
                                        (change)="fileChanged($event)"  />
                                </div>
                            </div>
                            <div class="row" *ngIf="imageSelected">
                                
                                    <div class="col-sm-4 col-md-4 col-lg-4 col-xl-4">
                                        <button class="btn btn-success" disabled>{{fileno}} Images</button>
                                    </div>
                                    <div class="col-sm-4 col-md-4 col-lg-4 col-xl-4">
                                        <button class="btn btn-danger" (click)="removeUploadedFile()">Remove All!</button>
                                    </div>
                                
                            </div>
                            <div class="row">
                                <div class="pp text-center" *ngIf="imageSelected">
                                    <label for="file-upload" class="custom-file-upload">
                                        <i class="fa fa-cloud-upload"></i>Click Here To Change All Images
                                    </label>
                                    <input id="file-upload" type="file" (change)="fileChanged($event)" accept=".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*" />
                                </div>
                            </div>
                            <div class="row" *ngIf="imageSelected">
                                <h2 class="text-success"><strong>New Uploaded Image</strong></h2>
                            </div>                    
                            <div class="row">
                                <div class="col-sm-4 col-md-4 col-lg-4 col-xl-4" *ngFor="let url of urls;let i=index" id="ram"
                                    style="width:400px">
                                    <img [src]="url" alt="thumbnail" class="img-thumbnail" style="width: 300px;height: 300px;">
                                    <i class="fas fa-trash-alt" id="deleteIcon" (click)="removeNew(i)"></i>
                                </div>
                            </div>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="5">
                        <a ngbNavLink>Submission</a>
                        <ng-template ngbNavContent>
                            <div class="row mt-4 px-5">
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <button class="btn btn-success" (click)="submit()" >Submit</button>
                                </div>        
                            </div>
                           
                        </ng-template>
                    </li>
                  </ul>
              </div>          
          </form>
        </div>
      </div>
      <div class="row px-5">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div [ngbNavOutlet]="nav" class="mt-2"></div>
        </div>
        
      </div>
        
</div>
export class pricingCategoryInfo {
    name:string;
    id:Number;  
    
    
  
    constructor(obj:any){
        this.name=obj.name||'';
        this.id=obj.id||'';
        
    }
  }
import { Injectable} from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { User } from 'src/app/shared/models/user.model';

import { Router, ActivatedRoute } from '@angular/router';
import { BaseService } from 'src/app/shared/service/baseService';
import { HttpClient} from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';



@Injectable()

export class AuthService extends BaseService {
  url;

 
   constructor( public http:HttpClient,
  public router:Router,
  public activeRoute: ActivatedRoute,)
  {
    super();
    this.url=this.baseUrl+'auth';
    
}

 login(data: User){
  return this.http.post(`${this.url}/login`, data, this.getOptions());
 }
 
 getprofileinfo(id:String){
  return this.http.get(`${this.url}/${id}`,this.getOptions())
 } 
 

register(data: User){
  return this.http.post(`${this.url}/register`,data,this.getOptions());
 }
forgotPassword(email:any){
  return this.http.post(`${this.url}/forgotPassword`, email, this.getOptions());
}

upload(data:any,files:any){
  const formData=new FormData();
  const xhr=new XMLHttpRequest();
  if(files&&files[0]){
      formData.append('img',files[0],files[0].name);
  }
  for(let key in data){
      formData.append(key,data[key]);
  }
  let upload=new Observable((observer)=>{
      xhr.onreadystatechange=()=>{
          if(xhr.readyState==4){
              if(xhr.status==200){
               observer.next(xhr.response);
              console.log('everything ok,  upload successful');
              }else{
                  console.log('error in upload',xhr.response);
            observer.error(xhr.response);
              }
             
          }
      }
  })
  let URL;
  let method;
  
  if(data._id){
      URL=`${this.url}/${data._id}?token=${localStorage.getItem('token')}`
      method="PUT"
  }else{
      URL=`${this.url}/register`
      method="POST"
  }
  xhr.open(method,URL,true);
  xhr.send(formData);
  return upload;
}
resetPassword(data:any) {
  return this.http.post(this.url + '/reset-password/' + data.token, data, this.getOptions());
}
verifyAccount(data:any){
  return this.http.post(this.url + '/account-verify/' + data.token, data, this.getOptions());
}
resetAccountActivation(email:any){
  return this.http.post(`${this.url}/resetaccountverification`, email, this.getOptions());
}


}
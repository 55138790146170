import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { ForgotpasswordComponent } from './forgotpassword/forgotpassword.component';
import { ActivateaccountComponent } from './activateaccount/activateaccount.component';
import { authenticationRouterModule } from './authentication.routing';
import { AuthService } from './services/auth.service';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { ResetpasswordComponent } from './resetpassword/resetpassword.component';
import { VerifyaccountComponent } from './verifyaccount/verifyaccount.component';



@NgModule({
  declarations: [LoginComponent, ForgotpasswordComponent, ActivateaccountComponent, ResetpasswordComponent, VerifyaccountComponent],
  imports: [
    CommonModule,
    authenticationRouterModule,
    HttpClientModule,
    FormsModule
  ],
  providers:[AuthService]
})
export class AuthenticationModule { }

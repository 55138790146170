<div class="sidebar">
    <app-sidebar></app-sidebar>
  </div>
<div class="main">
    <div class="bg">
        <div class="row">
            <div class="col-12">
                <h1 class="text-center p-5 my-5">Welcome to Client Side</h1>
            </div>
        </div>
    </div>
</div>
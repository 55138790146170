import { Component, OnInit,ViewChild, HostListener, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { MdbTablePaginationComponent, MdbTableDirective } from 'angular-bootstrap-md';
import { MsgService } from 'src/app/shared/service/msg.service';
import { environment } from 'src/environments/environment';
import { consultationService } from '../services/consultation.service';
import { contactusService } from '../services/contactus.service';


@Component({
  selector: 'app-inquiry',
  templateUrl: './inquiry.component.html',
  styleUrls: ['./inquiry.component.scss']
})
export class InquiryComponent implements OnInit, AfterViewInit  {
  @ViewChild(MdbTablePaginationComponent, { static: true }) mdbTablePagination: MdbTablePaginationComponent;
  @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective
 
  elements: any = [];
  previous: any = [];
  headElements = ['S.No.', 'Name','Email','Phone','Message'];

  ads=[];
   constructor(
    private cdRef: ChangeDetectorRef,
    public msgService:MsgService,
    public contactUsService:contactusService
    ) {
    
     }

  ngOnInit() {

    this.mdbTable.setDataSource(this.elements);
    this.elements = this.mdbTable.getDataSource();
    this.previous = this.mdbTable.getDataSource();
    this.getBrands();
  }

  ngAfterViewInit() {
    this.mdbTablePagination.setMaxVisibleItemsNumberTo(50);

    this.mdbTablePagination.calculateFirstItemIndex();
    this.mdbTablePagination.calculateLastItemIndex();
    this.cdRef.detectChanges();
  }
  getBrands(){
    this.elements=[];
    this.contactUsService.getall().subscribe(
      (data:any)=>{
        console.log('teh data is>>>', data)
        this.msgService.showSuccess('Data Loading Success!!!');
        if(data.length !=0){
          
          // this.addAds.id=parseInt(data[0].id)+1;
          this.elements=data;
          this.mdbTable.setDataSource(this.elements);
          this.elements = this.mdbTable.getDataSource();
          this.previous = this.mdbTable.getDataSource();
          
        }
      },
      error=>{
        console.log(error)
        this.msgService.showWarning('Data loading error !!!')
      }
    )
  }
}

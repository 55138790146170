<div *ngIf="deleteAsk">
    <div class="modal fade in" id="deleteModal" data-toggle="modal" style="display: block;">
        <div class="modal-dialog">
          <div class="modal-content">
          
            <!-- Modal Header -->
            <div class="modal-header">
              <h4 class="modal-title text-danger">Delete Data From Server</h4>
              <button type="button" class="close" data-dismiss="modal" (click)="deleteModalClose()">&times;</button>
            </div>
            
            <!-- Modal body -->
            <div class="modal-body">
                <!-- <div *ngIf="imgShow">
                    <img src="{{imgUrl}}{{advInfo.image}}" class="img-fluid" alt="Responsive image" >  
                </div>                           -->
                
                <h5>Are you sure you want to delete the data?</h5>
                <div class="row">
                    <div class="col">
                        <button class="btn btn-danger" (click)="delete()">Yes</button>
                    </div>
                    <div class="col">
                        <button class="btn btn-primary" (click)="deleteModalClose()">No</button>
                    </div>
                </div>
            </div>
            
            <!-- Modal footer -->
            
            
          </div>
        </div>
      </div>
</div>
<div class="sidebar">
  <app-sidebar></app-sidebar>
</div>
<div class="main">
    <div class="bg">
        <div class="row">
            <div class="col-12">
                <h1 class="text-center p-5 my-5">Welcome to Client Side</h1>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 mt-3">
            <div class="heading-text ">
                <h4 class="text-center text-danger">Blog List</h4>
            </div>
        </div>
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
            
        </div>
    </div>
        <div class="row mt-3">
            <div class="container">
                <table mdbTable #tableEl="mdbTable" class="z-depth-1">
                  <thead>
                  <tr>
                    <th *ngFor="let head of headElements; let i = index" scope="col">{{head}}
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr *ngFor="let el of elements; let i = index">
                    <th *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex"
                        scope="row">{{i+1}}</th>
                    <td *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex">{{el.title}}</td>
                   
                    <td *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex">{{el.name}}</td>
                    <td *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex">{{el.author_id}}</td>
                    <td *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex">{{el.date}}</td>
                    <td *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex">
                    <img src="./../../../assets/images/cdr logo 1.png" *ngIf="!el.image" style="height: 150px; width: 150px;">
                    <img src="{{blogImageUrl}}{{el.image}}" *ngIf="el.image" style="height: 150px; width: 150px;">
                    </td>
                   
                    <td *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex"><i class="fa fa-eye" aria-hidden="true" (click)="view(el._id)"></i></td>
                    
                    <td *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex"><i class="fa fa-trash" aria-hidden="true" (click)="askDelete(true, el)"></i></td>
                  </tr>
                  </tbody>
                  <tfoot class="grey lighten-5 w-100">
                  <tr>
                    <td colspan="4">
                      <mdb-table-pagination [tableEl]="tableEl" [searchDataSource]="elements"></mdb-table-pagination>
                    </td>
                  </tr>
                  </tfoot>
                </table>
              </div>
        </div>
</div>
<div class="home-content1 ">
  <div class="home1">
    <div class="container">
      <div class="row">
        <h1>Facing <span class="top_text_color">Trouble</span> Writing CDR Report?</h1>
      </div>
      <p class="home_p_text text-center">Get in touch with our experienced writer to help you writing your Report.<br>
        Avoid Possible Rejections.
      
    </div>
  </div>
  <div class="row px-5">
    <div class=" text-center">
      <button class="home_butt" onclick="Chatra('openChat', true)">BUY NOW</button>
    </div>

  </div>
</div>
<div class="home_content2 container">
  <div class="row">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
      <div class="text-center">
        <h1>OUR <span class="home_content2_head">OFFERS</span></h1>
      </div>
    </div>        
  </div>
  <div class="row offers">
    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 margin_set">
      <div class="text-center">
        <div class="contents">
          <h3>Zero Plagiarism Issues</h3>
          <p>Plagiarism free & high quality contents are provided by our experienced Writers.
          <div class="offer_contents_img1">
            <img src="./../../../assets/images/file_logo.png">
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 margin_set">
      <div class="text-center">
        <div class="contents">
          <h3>Unique contents</h3>
          <p>Quality & standard of contents is our major priority to avoid Rejection of CDR
          <div class="offer_contents_img2">
            <img src="./../../../assets/images/file_logo.png">
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 margin_set">
      <div class="text-center">
        <div class="contents">
          <h3>Team of Experts</h3>
          <p>Highly experienced writers help you in writing your assessment & will assist in increasing your grade. 
          <div class="offer_contents_img3">
            <img src="./../../../assets/images/team_of_experts_icon.png">
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row offers">
    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 margin_set">
      <div class="text-center">
        <div class="contents2">
          <h3>24/7 Support</h3>
          <p>We are available for 24/7 to support our valued customers. Our CSR service are always ready to assist you.
          <div class="offer_contents_img4">
            <img src="./../../../assets/images/support_icon.png">
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 margin_set">
      <div class="text-center">
        <div class="contents2">
          <h3>On Time Delivery</h3>
          <p>Timely delivered service will assist to avoid penalty. We ensure our services are deliverd before deadline.
          <div class="offer_contents_img5">
            <img src="./../../../assets/images/delivery_icon.png">
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 margin_set">
      <div class="text-center">
        <div class="contents2">
          <h3>Best Value for Money</h3>
          <p>Our pricing package guarantee ensures best service at affordable price than others. We provide premium package to our customers.
          <div class="offer_contents_img6">
            <img src="./../../../assets/images/money_icon.png">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="home_content3 mt-3 container">
  <div class="row">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
      <div class="text-center">
        <h1>HOW WE <span class="home_content3_head">WORK</span></h1>
      </div>
    </div>        
  </div>
  <div class="row">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
      <div class="text-center">
        <h4 class="top_section_content px-4 py-4">We at CDR Writing Service focus on providing quality and high standard work and effective process of Writing, Reviewing and Submiting our 
          customers work within deadline. Some steps are followed by us to achive the best result from our works. Some of these process are: </h4>
      </div>      
    </div>
  </div>
  <div class="row px-3 mt-2">
    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
      <div class="text-center">
        <img class="how_we_work_img" src='./../../../assets/images/order-service-vector.png'>
      </div>      
    </div>
    <div class="col-xl-8 col-lg-8 col-md-6 col-sm-12">
      <div class="how_we_work_text">
        <h4 >Order a service from us</h4>
        <p class="how_we_work_p">At first, you review all our details and different services provided by us in best package price. Then, book a service from us and make the initial payment.
          Finally submit your CV/Resume and other necessary details to write CDR report by our writers.        
      </div>  
      <div class="how_we_work_button">
        <button class="hww_butt" onclick="Chatra('openChat', true)">Order Now</button>
      </div>
    </div>
  </div>
  <div class="row px-3 mt-2">
    <div class="col-xl-8 col-lg-8 col-md-6 col-sm-12">
      <div class="how_we_work_text">
        <h4 >Our Experts Analyse your requirements and plans</h4>
        <p class="how_we_work_p">Our expert team of writers and Engineers will prepare a plagiarism free, detailed content and unique CDR report 
          writing facility for you. Our Writers make sure that the CDR Report fulfills all the requirements for successful EA Assessment.         
      </div>  
      <div class="how_we_work_button">
        <button class="hww_butt" onclick="Chatra('openChat', true)">Order Now</button>
      </div>
    </div>
    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
      <div class="text-center">
        <img class="how_we_work_img" src='./../../../assets/images/expert-analyse-vector.png'>
      </div>      
    </div>    
  </div>  
  <div class="row px-3 mt-2">
    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
      <div class="text-center">
        <img class="how_we_work_img" src='./../../../assets/images/representative-vector.png'>
      </div>      
    </div>
    <div class="col-xl-8 col-lg-8 col-md-6 col-sm-12">
      <div class="how_we_work_text">
        <h4 class="mt-3">Our representative will send draft copy and contact you</h4>
        <p class="how_we_work_p">Once the report is complete, our team will mail you draft copy of Report to make required correction and verification. 
          Changes to be made must be indicated by you in the Report.        
      </div>  
      <div class="how_we_work_button">
        <button class="hww_butt" onclick="Chatra('openChat', true)">Order Now</button>
      </div>
    </div>
  </div>
  <div class="row px-3 mt-2">
    <div class="col-xl-8 col-lg-8 col-md-6 col-sm-12">
      <div class="how_we_work_text">
        <h4 >Reveice a Complete Report on time</h4>
        <p class="how_we_work_p">We review your Report until you are satisfied with our service. Once, it is finalized our team will draft you CDR report. 
          Final copy of report will be provided only after your satisfaction.          
      </div>  
      <div class="how_we_work_button">
        <button class="hww_butt" onclick="Chatra('openChat', true)">Order Now</button>
      </div>
    </div>
    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
      <div class="text-center">
        <img class="how_we_work_img" src='./../../../assets/images/vector 3.png'>
      </div>      
    </div>    
  </div>  
</div>
<div class="home_content4 mt-4 ">
  <div class="container">
    <div class="row px-3">
      <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12">
        <div class="get_in_touch pt-4">
          <h4 class="pt-5"><span>GET IN TOUCH</span> WITH OUR EXPERTS ONLINE</h4>
          <p class="get_in_touch_p">Our experienced writers will make sure the result is free from plagiarim, refined and proofread. Stop wasting your Time and Money
            in incompetent agencies.        
        </div>  
        <div class="get_in_touch_button">
          <button class="get_butt1">Facebook</button>
          <button class="get_butt2">Whatsapp</button>
        </div>
      </div>
      <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 ">
        <div class="get_in_touch_form_head mt-5">
          <h4 class="text-center pt-2">In Need Of Assistance ?</h4>
        </div>      
        <div class="box_color py-3 px-4">
          <form class="form-group " #dataForm="ngForm">
            <div class="mb-4">
              <input class="form-control" type="text" placeholder="Full Name" [(ngModel)]="assistantData.name"
          name="Name" #nameis="ngModel" required >
          
          <input class="form-control " type="text" placeholder="Email" [(ngModel)]="assistantData.email"
          name="Email" #emailis="ngModel" required email >
            
          <input class="form-control" type="number" placeholder="Phone" [(ngModel)]="assistantData.phone"
          name="Phone" #phoneis="ngModel" required>
          
          <input class="form-control" type="text" placeholder="Country" [(ngModel)]="assistantData.country"
          name="Country" #countryis="ngModel" required>
          
          <input class="form-control" type="text" placeholder="Engineering Discipline" [(ngModel)]="assistantData.engineeringDiscipline"
          name="EngineeringDiscipline" #engineeringDisciplineis="ngModel" required>
          
          <input class="form-control" type="text" placeholder="Report Type" [(ngModel)]="assistantData.reportType"
          name="ReportType" #reportTypeis="ngModel" required>
          
          <textarea class="form-control"  placeholder="Description" [(ngModel)]="assistantData.discription"
          name="Discription" #discriptionis="ngModel" required></textarea>
         <br>
          <input type="checkbox" [(ngModel)]="assistantData.termsAccepted"
              name="termsAccepted" #termsAcceptedis="ngModel" required> <span><a>Terms and Conditions</a> Applied.</span>
            </div>
            <div class="faq_form_button text-center">
              <button class="button_faq" *ngIf="!submitting"  (click)="submitAssistanceInfo()">Submit</button>
              <button class="button_faq" *ngIf="submitting" disabled>Submitting...</button>
          </div> 
          </form>
        </div>
      </div>    
    </div> 
  </div>
   
</div>
<div class="home_content5 mt-3">
  <div class="great_deals1">
    <div class="row">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
        <div class="text-center">
          <h4 class="mb-3">EXPLORE <span>GREAT DEALS</span> AND PACKAGES</h4>          
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-xl-2 col-lg-2 col-md-12 col-sm-12" id="hide"></div>
        <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12">
          <div class="text-center">          
            <h6 class="mb-3" >We have the best value for money pricing on all your writings, pricing starting only at $299. </h6>
            <button class="great_deals_butt" [routerLink]="['/pricing']">View Details</button>
          </div>
        </div>
        <div class="col-xl-2 col-lg-2 col-md-12 col-sm-12" id="hide"></div>
      </div>
    </div>  
    <div class="packages mt-5">
      <div class="container">
        <div class="row">
          <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12" id="change_padding">
            <div class="package1">
              <div class="package1_head px-5 pt-3">
                  <h4 class="text-center">Standard CDR Report Package</h4>
              </div>
              <div class="package1_price mb-3">
                <h4 class="text-center"><span><sup>$</sup></span>299</h4>
              </div>
              <div class="package1_title mb-4">
                  <h5 class="text-center">What you will receive</h5>
              </div>
              <div class="package1_description mb-5 px-4">
                  <h6>lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem psumlorem ipsum dolor sit amet lorem ipsum 
                    dolor sit amet lorem ipsum</h6>
              </div>
              <div class="text-center mt-5 pt-4">
                  <button class="pack_button" onclick="Chatra('openChat', true)">Order Now</button>
              </div>
            </div>
          </div>
          <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12" id="change_padding">
            <div class="package2">
              <div class="package2_head pt-3">
                <h4 class="text-center">SPECIAL DISCOUNT</h4>
              </div>
              <div class="package1_head px-5 pt-3">
                <h4 class="text-center">Complete CDR Report Package</h4>
              </div>
              <div class="package1_price mb-2">
                <h4 class="text-center"><span><sup>$</sup></span>899</h4>
              </div>
              <div class="package1_title mb-4 ml-3">
                <h5 class="text-center">What you will receive</h5>
              </div>
              <div class="package2_description mb-5 px-3 ml-4">
                <h6><i class="far fa-check-circle"></i>&nbsp; lorem ipsum dolor sit </h6>
                <h6><i class="far fa-check-circle"></i>&nbsp; amet lorem ipsum dolor sit </h6>
                <h6><i class="far fa-check-circle"></i>&nbsp;amet lorem psumlorem ipsum dolor </h6>
                <h6><i class="far fa-check-circle"></i>&nbsp;sit amet lorem ipsum dolor </h6>
                <h6><i class="far fa-check-circle"></i>&nbsp;sit amet lorem ipsum</h6>
              </div>
              <div class="text-center mt-5 pt-4">
                <button class="pack2_button" onclick="Chatra('openChat', true)">Order Now</button>
              </div>
            </div>
          </div>
          <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12" id="change_padding">
            <div class="package3">
                <div class="package1_head px-5 pt-3">
                <h4 class="text-center">Standard CDR Report Package</h4>
                </div>  
                <div class="package1_price mb-2">
                  <h4 class="text-center"><span><sup>$</sup></span>599</h4>
                </div>
                <div class="package1_title mb-4 ml-3">
                  <h5 class="text-center">What you will receive</h5>
                </div>
                <div class="package1_description mb-5 px-3 ml-4">
                    <h6>lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem psumlorem ipsum dolor sit amet lorem ipsum 
                      dolor sit amet lorem ipsum</h6>
                </div>
                <div class="text-center mt-5 pt-4">
                    <button class="pack_button" onclick="Chatra('openChat', true)">Order Now</button>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>  
  </div>
</div>
<div class="home_contents6 mt-5">
  <div class="clients_testimonials">
    <div class="container">
      <div class="row mb-5">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
          <div class="clients_testimonials_head">
            <h4 class="text-center">CLIENT <span>TESTIMONIALS</span></h4>
          </div>

        </div>        
      </div>
      <!-- <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
          
        </div>        
      </div> -->
      <mdb-carousel [isControls]="true" class="carousel-multi-item multi-animation" [type]="'carousel-multi-item'"
              [animation]="'slide'">
          <mdb-carousel-item *ngFor="let item of slides; let i = index">
            <div class="col-md-6 pb-2" *ngFor="let card of item; let cardIndex = index">
              <div class="row">
                <div class="col-md-12 col-sm-8">
                  <div class="testimonial_image">
                    <img src={{card.img}}>
                  </div>
                </div>     
                <div class="col-md-12 col-sm-8">
                  <div class="message mb-2">
                    <h4>{{card.name}}</h4>
                  </div>
                  <div class="stars mb-4">
                    <i class="far fa-star"></i><i class="far fa-star"></i><i class="far fa-star"></i><i class="far fa-star"></i><i class="far fa-star"></i>
                  </div>
                  <div class="description">
                    <h5>{{card.message}}</h5>
                  </div>
                </div>          
              </div>
             </div>
          </mdb-carousel-item>
        </mdb-carousel>
    </div>
      
  </div>
</div>
<div class="home_contents7 mt-5 mb-5">
  <div class="latest_news">
    <div class="container">
      <div class="row mb-5">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
          <div class="latest_news_head pt-4">
            <h4 class="text-center">LATEST <span>NEWS</span></h4>
          </div>
        </div> 
      </div>
      <div class="row">
        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
          <div class="latest_news_image">
            <img src="./../../../assets/images/latest_news_1.png" class="img-fluid" alt="Responsive image">
          </div>
          <div class="latest_news_date mt-2">
            <h6 class="text-center">Jan 5, 2021</h6>
          </div>
          <div class="latest_news_title mt-2">
            <h4>Lorem ipsum dolor sit ametec adipiscing eli.</h4>
          </div>
          <div class="latest_news_description mt-5">
            <h6>"Lorem ipsum dolor sit amet, consec adipiscing elit, sed do eiusmod lorem ipsum tempor.”</h6>
          </div>
          <div class="latest_news_butt mt-3 mb-2">
            <button>Read More ></button>
          </div>
        </div>
        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
          <div class="latest_news_image">
            <img src="./../../../assets/images/latest_news_2.png" class="img-fluid" alt="Responsive image">
          </div>
          <div class="latest_news_date mt-2">
            <h6 class="text-center">Jan 5, 2021</h6>
          </div>
          <div class="latest_news_title mt-2">
            <h4>Lorem ipsum dolor sit ametec adipiscing eli.</h4>
          </div>
          <div class="latest_news_description mt-5">
            <h6>"Lorem ipsum dolor sit amet, consec adipiscing elit, sed do eiusmod lorem ipsum tempor.”</h6>
          </div>
          <div class="latest_news_butt mt-3 mb-2">
            <button>Read More ></button>
          </div>
        </div>
        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
          <div class="latest_news_image">
            <img src="./../../../assets/images/latest_news_3.png" class="img-fluid" alt="Responsive image">
          </div>
          <div class="latest_news_date mt-2">
            <h6 class="text-center">Jan 5, 2021</h6>
          </div>
          <div class="latest_news_title mt-2">
            <h4>Lorem ipsum dolor sit ametec adipiscing eli.</h4>
          </div>
          <div class="latest_news_description mt-5">
            <h6>"Lorem ipsum dolor sit amet, consec adipiscing elit, sed do eiusmod lorem ipsum tempor.”</h6>
          </div>
          <div class="latest_news_butt mt-3 pb-4">
            <button>Read More ></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from 'src/app/shared/models/user.model';
import { MsgService } from 'src/app/shared/service/msg.service';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-resetpassword',
  templateUrl: './resetpassword.component.html',
  styleUrls: ['./resetpassword.component.scss']
})
export class ResetpasswordComponent implements OnInit {
  token;
  submitting: boolean = false;
  user;

confirmPassword:String='';
submitButtShow:boolean=false;
  constructor(
    public router: Router,
    public activeRoute: ActivatedRoute,
    public msgService: MsgService,
    public authService: AuthService
  ) { 
    
    this.user = new User({});
    this.token = this.activeRoute.snapshot.params['token'];
  }

  ngOnInit(): void {
    document.documentElement.scrollTop=0;
    
  }
  submit() {
    this.submitting = true;
    this.user.token = this.token;
    this.authService.resetPassword(this.user).subscribe(
      data => {
        this.msgService.showSuccess('Password reset success');
        this.router.navigate(['/auth/login']);
      },
      error => {
        this.submitting = false;
        this.msgService.showError(error);
      }
    )
  }
  ngDoCheck(){
    if(this.confirmPassword==(this.user).password){
      this.submitButtShow=true;
    }else{
      this.submitButtShow=false;
    }
  }

}

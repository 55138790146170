import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AddBlogpageComponent } from './add-blogpage/add-blogpage.component';
import { EditBlogpageComponent } from './edit-blogpage/edit-blogpage.component';
import { AddTestimonialsComponent } from './add-testimonials/add-testimonials.component';
import { InquiryComponent } from './inquiry/inquiry.component';
import { SettingsComponent } from './settings/settings.component';
import { AddSampleComponent } from './add-sample/add-sample.component';
import { ListSampleComponent } from './list-sample/list-sample.component';
import { PageinfoComponent } from './pageinfo/pageinfo.component';
import { ConsultationComponent } from './consultation/consultation.component';
import { ExpertsComponent } from './experts/experts.component';
import { PricingPackagesComponent } from './pricing-packages/pricing-packages.component';
import { FaqComponent } from './faq/faq.component';
import { AnzscoCodeComponent } from './anzsco-code/anzsco-code.component';
import { PricingCategoriesComponent } from './pricing-categories/pricing-categories.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { dashboardRouterModule } from './dashboard.routing';
import { ViewBlogpageComponent } from './view-blogpage/view-blogpage.component';
import { ListFaqsComponent } from './list-faqs/list-faqs.component';
import { pricingCategoryService } from './services/pricingcategories.service';
import { assistanceService } from './services/assistance.service';

import { consultationService } from './services/consultation.service';

import { pricingPackageService } from './services/pricingpackage.service';
import { setupsService } from './services/setups.service';
import { testimonialsService } from './services/testimonials.service';
import { userQuestionService } from './services/userquestion.service';
import { faqService } from './services/faq.service';
import {contactusService} from './services/contactus.service'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { WavesModule, TableModule } from 'angular-bootstrap-md';
import {QuillModule} from 'ngx-quill';
import { AssistanceComponent } from './assistance/assistance.component';
import { UserquestionComponent } from './userquestion/userquestion.component';
import { expertsService } from './services/experts.service';
import { blogService } from './services/blog.service';
import { SharedModule } from '../shared/shared.module';
import { sampleReqService } from './services/sampleReq.service';
import { specialPricingService } from './services/specialPricing.service';
import { SpecialpricingComponent } from './specialpricing/specialpricing.component';


@NgModule({
  declarations: [DashboardComponent, AddBlogpageComponent, EditBlogpageComponent, AddTestimonialsComponent, InquiryComponent, SettingsComponent, AddSampleComponent, ListSampleComponent, PageinfoComponent, ConsultationComponent, ExpertsComponent, PricingPackagesComponent, FaqComponent, AnzscoCodeComponent, PricingCategoriesComponent, ViewBlogpageComponent, ListFaqsComponent, AssistanceComponent, UserquestionComponent, SpecialpricingComponent],
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    dashboardRouterModule,
    NgbModule,
    WavesModule, TableModule,
    SharedModule,

    QuillModule.forRoot(),
  
  ],
  providers:[pricingCategoryService,assistanceService, consultationService, contactusService,faqService,
  pricingPackageService, setupsService, testimonialsService, userQuestionService, expertsService, 
blogService, sampleReqService, specialPricingService ]
})
export class DashboardModule { }

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User } from 'src/app/shared/models/user.model';
import { MsgService } from 'src/app/shared/service/msg.service';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loggedIn:boolean=false;
  user;
  userform;
  constructor(
    public router:Router,
    public authService:AuthService,
    public msgService:MsgService,
  ) { 
    this.user=new User({});
   this.userform=new User({});
  }

  ngOnInit(): void {
    document.documentElement.scrollTop=0;
    this.user=JSON.parse(localStorage.getItem('user')||'{}');
   this.loggedIn=JSON.parse(localStorage.getItem('loggedInUser')||'{}');
   if(this.loggedIn==null){this.loggedIn=false;}
   if(this.user!=null){}
  }
  login(){    
    this.authService.login(this.userform).subscribe(
      (data: any)=>{        
        this.msgService.showSuccess(`welcome ${data.user.username}`);       
        localStorage.setItem('token',data.token);
        localStorage.setItem('user',JSON.stringify(data.user));
        localStorage.setItem('loggedInUser','true');
        this.loggedIn=true;
        this.user=JSON.parse(localStorage.getItem('user')||'{}');
        this.loggedIn=JSON.parse(localStorage.getItem('loggedInUser')||'{}'); 
        this.router.navigate(['/dashboard/dashboard']) 
         },
      error =>{        
        this.msgService.showWarning('couldnot login'); 
        console.log(error)
       
      }
    );
  }

}

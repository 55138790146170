import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { FormsModule } from '@angular/forms';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { MsgService } from './service/msg.service';
import { SidebarComponent } from './sidebar/sidebar.component';


@NgModule({
  declarations: [PagenotfoundComponent, HeaderComponent, FooterComponent, SidebarComponent],
  imports: [
    RouterModule,
    CommonModule,
    MDBBootstrapModule.forRoot(),
    FormsModule,
    
  ],
  exports:[HeaderComponent, FooterComponent,  PagenotfoundComponent,SidebarComponent],
  providers:[MsgService]
})
export class SharedModule { }

<div class="faqs_page">
  <div class="container">
    <div class="row">
      <div class="col-xl-12 col-lg-12 col-sm-12 col-md-12">
        <div class="faq_head mb-5">
          <h4 class="text-center"><span>FAQ</span>s</h4>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-12 col-lg-12 col-sm-12 col-md-12">
        <div class="question_title mb-3">
          <h4>What we Are</h4>
        </div>
        <div class="question_ans mb-5">
          <h5>
            Lorem Ipsum Dolor sit amet Set ersoicitas unde iste natis error sit
            attem Lorem Ipsum Dolor sit amet Set ersoicitas unde iste natis
            erLorem Ipsum Dolor sit amet Set ersoicitas unde iste natis error
            sit attem Lorem Ipsum Dolor sit amet Set ersoicitas unde iste natis
            erLorem Ipsum Dolor sit amet Set ersoicitas unde iste natis error
            sit attem
          </h5>
        </div>
        <div class="question_title mb-3">
          <h4>Why us ?</h4>
        </div>
        <div class="question_ans mb-5">
          <h5>
            <ul>
              <li>100% Plagiarism Free.</li>
              <li>Fully Confidential.</li>

              <li>All engineering disciplines / Domain specific writers.</li>
              <li>On Time Delivery.</li>
              <li>Best Price.</li>
              <li>CDR tutoring and free consultation.</li>
              <li>Unlimited Feedback.</li>
              <li>Perfect CDR in Hand.</li>
              <li>Quality Service.</li>
              <li>Full Satisfaction.</li>
              <li>
                Trusted by immigration agents for their clients’ skill
                assessment.
              </li>
            </ul>
          </h5>
        </div>
        <div class="question_title mb-3">
          <h4>What we Are</h4>
        </div>
        <div class="question_ans mb-5">
          <h5>
            Lorem Ipsum Dolor sit amet Set ersoicitas unde iste natis error sit
            attem Lorem Ipsum Dolor sit amet Set ersoicitas unde iste natis
            erLorem Ipsum Dolor sit amet Set ersoicitas unde iste natis error
            sit attem Lorem Ipsum Dolor sit amet Set ersoicitas unde iste natis
            erLorem Ipsum Dolor sit amet Set ersoicitas unde iste natis error
            sit attem
          </h5>
        </div>
        <div class="question_title mb-3">
          <h4>What we Are</h4>
        </div>
        <div class="question_ans mb-5">
          <h5>
            Lorem Ipsum Dolor sit amet Set ersoicitas unde iste natis error sit
            attem Lorem Ipsum Dolor sit amet Set ersoicitas unde iste natis
            erLorem Ipsum Dolor sit amet Set ersoicitas unde iste natis error
            sit attem Lorem Ipsum Dolor sit amet Set ersoicitas unde iste natis
            erLorem Ipsum Dolor sit amet Set ersoicitas unde iste natis error
            sit attem
          </h5>
        </div>
        <div class="question_title mb-3">
          <h4>What we Are</h4>
        </div>
        <div class="question_ans mb-5">
          <h5>
            Lorem Ipsum Dolor sit amet Set ersoicitas unde iste natis error sit
            attem Lorem Ipsum Dolor sit amet Set ersoicitas unde iste natis
            erLorem Ipsum Dolor sit amet Set ersoicitas unde iste natis error
            sit attem Lorem Ipsum Dolor sit amet Set ersoicitas unde iste natis
            erLorem Ipsum Dolor sit amet Set ersoicitas unde iste natis error
            sit attem
          </h5>
        </div>
      </div>
    </div>
    <div class="row mt-4 mb-5">
      <div class="col-xl-8 col-lg-8 col-sm-12 col-md-12">
        <div class="cant_find">
          <h3><span>CAN’T FIND</span> WHAT YOU LOOKING FOR ?</h3>
          <h5></h5>
          Send us you querry and out team will respond to it soon.
        </div>
      </div>
      <div class="col-xl-4 col-lg-4 col-sm-12 col-md-12">
        <div class="faq_question_form px-3 py-4">
          <form class="text-center form-group" #dataForm="ngForm">
            <div class="mb-4">
              <input
                class="form-control"
                type="text"
                placeholder="What do you want to ask ?"
                [(ngModel)]="userQuestion.question"
                name="Question"
                #questionis="ngModel"
                required
              />
             
              <input
                class="form-control"
                type="text"
                placeholder="Email"
                [(ngModel)]="userQuestion.email"
                name="Email"
                #emailis="ngModel"
                required
                email
              />
              
              <input
                type="checkbox"
                [(ngModel)]="userQuestion.termsAccepted"
                name="termsAccepted"
                #termsAcceptedis="ngModel"
                required
              />
              <span><a>Terms and Conditions</a> Applied.</span>
            </div>
            <div class="faq_form_button text-center">
              <button class="button_faq" *ngIf="!submitting" (click)="submitForm()">Submit</button>
              <button class="button_faq" *ngIf="submitting" disabled>
                Submitting...
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

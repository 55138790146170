import { HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';


export class BaseService{
    baseUrl;
    constructor(){
        this.baseUrl=environment.BaseUrl;
    }
    getOptions(){
        let options = {
          headers:new HttpHeaders({
            'Content-Type': 'application/json'
            
          })
        }
        return options;
       }
       getOptionsWithToken(){
        let options = {
          headers:new HttpHeaders({
            'Content-Type':'application/json',
            'Authorization':localStorage.getItem('token')||'{}'
          })
          
        }
        return options;
       }
}
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AboutusComponent } from './aboutus/aboutus.component';
import { ContactusComponent } from './contactus/contactus.component';
import { FaqComponent } from './faq/faq.component';
import { HomeComponent } from './home/home.component';
import { HowtoComponent } from './howto/howto.component';
import { PricingComponent } from './pricing/pricing.component';
import { ServicesComponent } from './services/services.component';
import { ServicesbynameComponent } from './servicesbyname/servicesbyname.component';
import { SampleComponent } from './sample/sample.component';
import { SamplebynameComponent } from './samplebyname/samplebyname.component';
import { AnzscoCodeComponent } from './anzsco-code/anzsco-code.component';
import { CdrRejectionComponent } from './cdr-rejection/cdr-rejection.component';

import { BlogComponent } from './blog/blog.component';
import { BlogByNameComponent } from './blog-by-name/blog-by-name.component';
import { generalRouterModule } from './general.routing';

import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonsModule, InputsModule,  CardsModule } from 'angular-bootstrap-md';
import { CarouselModule, WavesModule, TableModule  } from 'angular-bootstrap-md'
import { HttpClientModule } from '@angular/common/http';

import { TermsofuseComponent } from './termsofuse/termsofuse.component';



@NgModule({
  declarations: [AboutusComponent, ContactusComponent, FaqComponent, HomeComponent, HowtoComponent, PricingComponent, ServicesComponent, ServicesbynameComponent, SampleComponent, SamplebynameComponent, AnzscoCodeComponent, CdrRejectionComponent, BlogComponent, BlogByNameComponent, TermsofuseComponent],
  imports: [
    CommonModule,
    generalRouterModule,
    MDBBootstrapModule.forRoot(),
    FormsModule,
    WavesModule,
    CardsModule,
    ReactiveFormsModule,
    ButtonsModule,
    InputsModule,
    CarouselModule,
    HttpClientModule,
    TableModule
    
  ],
  
})
export class GeneralModule { }

<div *ngIf="requestForSample">
    <div class="modal fade in" id="myModal" data-toggle="modal" style="display: block;">
        <div class="modal-dialog ">
          <div class="modal-content">
            <!-- Modal body -->
            <div class="modal-body">
                <i class="fas fa-times-circle" (click)="addCategoriesModalClose()"></i>
                <form class="form-group " #addBackgroundForm="ngForm">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">                            
                            <input class="form-control" type="text" placeholder="Name" [(ngModel)]="sampleReqform.name"
                                name="Name" required #nameis="ngModel">
                            <div class="danger" [hidden]="nameis.valid || nameis.pristine">
                                required field
                            </div> 

                            <input class="form-control" type="text" placeholder="Email" [(ngModel)]="sampleReqform.email"
                            name="Email"  #emailis="ngModel">
                            <div class="danger" [hidden]="emailis.valid || emailis.pristine">
                                required field
                            </div>
                            <input class="form-control" type="text" placeholder="Sample Name" [(ngModel)]="sampleReqform.sampleName"
                            name="sampleName"  #sampleNameis="ngModel" disabled>
                            <input class="form-control" type="number" placeholder="Phone Number" [(ngModel)]="sampleReqform.phoneNumber"
                            name="PhoneNumber"  #phoneNumberis="ngModel" required>
                            <div class="danger" [hidden]="phoneNumberis.valid || phoneNumberis.pristine">
                                required field
                            </div>
                        </div>                                
                    </div>
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div class="text-center">
                                <button class="btn btn-danger" (click)="submitSampleReq()"  [disabled]="addBackgroundForm.form.invalid" *ngIf="!samplereqSubmitting">Submit</button>
                                <button class="btn btn-danger"   disabled *ngIf="samplereqSubmitting">Submitting...</button>
                            </div>
                        </div>        
                    </div>
                    
                
                
                
                </form>
            </div>
            
            <!-- Modal footer -->
           
            
          </div>
        </div>
      </div>
</div>

<div class="sample_page">
    <div class="container">
        <div class="row sample_page_first mb-3">
            <div class="col-xl-7 col-lg-7 col-md-6 col-sm-12">
                <div class="sample_page_first_title">
                    <h4><span>CDR</span> REPORT SAMPLES</h4>
                </div>
                <div class="sample_page_first_desc pt-4">
                    <p>Lorem Ipsum Dolor sit amet Set ersoicitas unde iste n este natis error sit attem Lorem Ipsum CDR Writing Set ersoicitas
                         unde iste natis erLorem Ipsum Dolor sit amet Set ersoicitas unde iste natis error sit attem.
                        Lorem Ipsum Dolor sit amet Set ersoicitas unde iste natis erLorem Ipsum Dolor sit amet Set ersoicitas unde iste 
                        natis error sit attem Lorem Ipsum Dolor sit amet Set ersoicitas unde iste natis erLorem Ipsum Dolor sit amet Set 
                        ersoicitas unde iste natis error sit attem Lorem Ipsum Dolor sit amet Set ersoicitas unde iste natis erLorem Ipsum 
                        Dolor sit amet Set ersoicitas unde iste natis error sit attem Lorem Report Writing amet Set ersoicitas unde iste 
                        natis erLorem Ipsum Dolor sit amet Set ersoicitas unde iste natis error sit attem Lore.</p>
                </div>
            </div>
            <div class="col-xl-5 col-lg-5 col-md-6 col-sm-12">
                <div class="sample_image1">
                    <img src="./../../../assets/images/cdr_report_sample_behind.png" alt="thumbnail" class="img-thumbnail"
                    style="width: 300px; margin-left: 8rem;">
                </div>
                <div class="sample_image2">
                    <img src="./../../../assets/images/cdr_report_sample_foreground.png" class="img-fluid" alt="Responsive image">
                </div>
            </div>
        </div>
        <div class="row mt-5 mb-5">
            <div class="col-xl-12 col-lg-12 col-sm-12 col-md-12">
                <div class="cdr_sample_box">
                    <h4 class="text-center">CDR REPORT <span class="theme-color">SAMPLES</span></h4>
                    <h6 class="mt-3">Lorem Ipsum Dolor sit amet Set ersoicitas unde iste n este natis error sit attem Lorem Ipsum CDR Writing Set 
                        ersoicitas unde iste natis erLorem Ipsum Dolor sit amet Set ersoicitas unde iste natis error sit attem.</h6>
                </div>
            </div>
        </div>
        <div class="row mt-5">
            <div class="col-xl-3 col-lg-3 col-sm-12 col-md-3">
                <div class="sample_box">
                    <div class="sample_image">
                        <img src='./../../../assets/images/report_sample_1.png' >
                    </div>
                    <div class="sample_title" (click)="showsampleReqModal()">
                        <h4 class="text-center">CDR Report Samples</h4>
                    </div>
                    <div class="sample_description px-4 mt-4">
                        <h6>Tele Communication engineer ANZSCO 129384</h6>
                    </div>
                </div>
            </div>
            <div class="col-xl-3 col-lg-3 col-sm-12 col-md-3">
                <div class="sample_box">
                    <div class="sample_image">
                        <img src='./../../../assets/images/report_sample_2.png' >
                    </div>
                    <div class="sample_title">
                        <h4 class="text-center">CDR Report Samples</h4>
                    </div>
                    <div class="sample_description px-4 mt-4">
                        <h6>Tele Communication engineer ANZSCO 129384</h6>
                    </div>
                </div>
            </div>
            <div class="col-xl-3 col-lg-3 col-sm-12 col-md-3">
                <div class="sample_box">
                    <div class="sample_image">
                        <img src='./../../../assets/images/report_sample_3.png' >
                    </div>
                    <div class="sample_title">
                        <h4 class="text-center">CDR Report Samples</h4>
                    </div>
                    <div class="sample_description px-4 mt-4">
                        <h6>Tele Communication engineer ANZSCO 129384</h6>
                    </div>
                </div>
            </div>
            <div class="col-xl-3 col-lg-3 col-sm-12 col-md-3">
                <div class="sample_box">
                    <div class="sample_image">
                        <img src='./../../../assets/images/report_sample_4.png' >
                    </div>
                    <div class="sample_title">
                        <h4 class="text-center">CDR Report Samples</h4>
                    </div>
                    <div class="sample_description px-4 mt-4">
                        <h6>Tele Communication engineer ANZSCO 129384</h6>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-xl-3 col-lg-3 col-sm-12 col-md-3">
                <div class="sample_box">
                    <div class="sample_image">
                        <img src='./../../../assets/images/report_sample_1.png' >
                    </div>
                    <div class="sample_title">
                        <h4 class="text-center">CDR Report Samples</h4>
                    </div>
                    <div class="sample_description px-4 mt-4">
                        <h6>Tele Communication engineer ANZSCO 129384</h6>
                    </div>
                </div>
            </div>
            <div class="col-xl-3 col-lg-3 col-sm-12 col-md-3">
                <div class="sample_box">
                    <div class="sample_image">
                        <img src='./../../../assets/images/report_sample_2.png' >
                    </div>
                    <div class="sample_title">
                        <h4 class="text-center">CDR Report Samples</h4>
                    </div>
                    <div class="sample_description px-4 mt-4">
                        <h6>Tele Communication engineer ANZSCO 129384</h6>
                    </div>
                </div>
            </div>
            <div class="col-xl-3 col-lg-3 col-sm-12 col-md-3">
                <div class="sample_box">
                    <div class="sample_image">
                        <img src='./../../../assets/images/report_sample_3.png' >
                    </div>
                    <div class="sample_title">
                        <h4 class="text-center">CDR Report Samples</h4>
                    </div>
                    <div class="sample_description px-4 mt-4">
                        <h6>Tele Communication engineer ANZSCO 129384</h6>
                    </div>
                </div>
            </div>
            <div class="col-xl-3 col-lg-3 col-sm-12 col-md-3">
                <div class="sample_box">
                    <div class="sample_image">
                        <img src='./../../../assets/images/report_sample_4.png' >
                    </div>
                    <div class="sample_title">
                        <h4 class="text-center">CDR Report Samples</h4>
                    </div>
                    <div class="sample_description px-4 mt-4">
                        <h6>Tele Communication engineer ANZSCO 129384</h6>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-xl-3 col-lg-3 col-sm-12 col-md-3">
                <div class="sample_box">
                    <div class="sample_image">
                        <img src='./../../../assets/images/report_sample_1.png' >
                    </div>
                    <div class="sample_title">
                        <h4 class="text-center">CDR Report Samples</h4>
                    </div>
                    <div class="sample_description px-4 mt-4">
                        <h6>Tele Communication engineer ANZSCO 129384</h6>
                    </div>
                </div>
            </div>
            <div class="col-xl-3 col-lg-3 col-sm-12 col-md-3">
                <div class="sample_box">
                    <div class="sample_image">
                        <img src='./../../../assets/images/report_sample_2.png' >
                    </div>
                    <div class="sample_title">
                        <h4 class="text-center">CDR Report Samples</h4>
                    </div>
                    <div class="sample_description px-4 mt-4">
                        <h6>Tele Communication engineer ANZSCO 129384</h6>
                    </div>
                </div>
            </div>
            <div class="col-xl-3 col-lg-3 col-sm-12 col-md-3">
                <div class="sample_box">
                    <div class="sample_image">
                        <img src='./../../../assets/images/report_sample_3.png' >
                    </div>
                    <div class="sample_title">
                        <h4 class="text-center">CDR Report Samples</h4>
                    </div>
                    <div class="sample_description px-4 mt-4">
                        <h6>Tele Communication engineer ANZSCO 129384</h6>
                    </div>
                </div>
            </div>
            <div class="col-xl-3 col-lg-3 col-sm-12 col-md-3">
                <div class="sample_box">
                    <div class="sample_image">
                        <img src='./../../../assets/images/report_sample_4.png' >
                    </div>
                    <div class="sample_title">
                        <h4 class="text-center">CDR Report Samples</h4>
                    </div>
                    <div class="sample_description px-4 mt-4">
                        <h6>Tele Communication engineer ANZSCO 129384</h6>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="services_page_form ">
    <div class="container">
        <div class="row">
            <div class="col-xl-8 col-lg-8 col-md-6 col-sm-12" id="service_change_padding">
                <div class="services_page_form_title py-5">
                    <h4 >GET <span>FREE CONSULTATION</span> WITH OUR EXPERTS.</h4>
                    <p>STOP ! wasting time and money searching agencies. At CDR Writing Australia, we offer free consultation to our 
                        esteemed cliens with with one of our expert on field. Our experts will gurantee your results to be refined, 
                        genuine and unique and plagarism free.</p>
                </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12" id="service_change_padding2">
                <div class="service_form">
                    <div class="service_form_head_box">
                        <h4 class="text-center py-3">FREE CONSULTATION</h4>
                    </div>
                    <div class="service_page_form_box px-3 py-4">
                        <form class="text-center form-group " #dataForm="ngForm">
                            <div class="mb-4">
                                <input class="form-control" type="text" placeholder="Full Name" [(ngModel)]="consultationForm.name"
                                    name="Name" #nameis="ngModel" required>                                
                                <input class="form-control " type="text" placeholder="Email" [(ngModel)]="consultationForm.email"
                                name="Email" #emailis="ngModel" required email >                                
                                <input class="form-control" type="number" placeholder="Contact Number" [(ngModel)]="consultationForm.phone"
                                    name="Phone" #phoneis="ngModel" required>                                
                                <input class="form-control" type="datetime-local" placeholder="Prefered Date & Time" [(ngModel)]="consultationForm.date"
                                    name="Date" #dateis="ngModel" required>
                            </div>      
                            <div class="service_form_button text-center">
                                <button class="button_faq" *ngIf="!submitting" (click)="submitConsultation()">Submit</button>
                                <button class="button_faq" *ngIf="submitting" disabled>Submitting...</button>
                            </div>         
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
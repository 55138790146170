import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import 'quill-mention';
import 'quill-emoji';

import { environment } from 'src/environments/environment';
import { MsgService } from 'src/app/shared/service/msg.service';
import { blogService } from '../services/blog.service';
import { blogInfo } from '../models for dashboard/blog.model';

@Component({
  selector: 'app-edit-blogpage',
  templateUrl: './edit-blogpage.component.html',
  styleUrls: ['./edit-blogpage.component.scss']
})
export class EditBlogpageComponent implements OnInit {
  active = 1;
  addBlogInfo;
  fileno:any;
  filesToUpload:any=[];
  imageSelected: boolean = false;
  urls = new Array<string>();
  date:any;
  imageUrl;
  blogid:any;

  test=(event:any)=>{
   
  }

  onSelectionChanged = (event:any) =>{
    if(event.oldRange == null){
      this.onFocus();
    }
    if(event.range == null){
      this.onBlur();
    }
  }

  onContentChanged = (event:any) =>{
 
  }

  onFocus = () =>{
    
  }
  onBlur = () =>{

  }
  htmlText ="<p>Testing</p>"
  htmlText2 ="<p>Testing</p>"
  hasFocus = false;


  atValues = [
    { id: 1, value: 'Fredrik Sundqvist', link: 'https://google.com' },
    { id: 2, value: 'Patrik Sjölin' }
  ];
  hashValues = [
    { id: 3, value: 'Fredrik Sundqvist 2' },
    { id: 4, value: 'Patrik Sjölin 2' }
  ]

  quillConfig={
    //toolbar: '.toolbar',
     toolbar: {
       container: [
         ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
         ['code-block'],
         [{ 'header': 1 }, { 'header': 2 }],               // custom button values
         [{ 'list': 'ordered'}, { 'list': 'bullet' }],
         [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
         [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
         [{ 'direction': 'rtl' }],                         // text direction

         [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
         [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

         [{ 'font': [] }],
         [{ 'align': [] }],

         ['clean'],                                         // remove formatting button

         ['link'],
         //['link', 'image', 'video']  
        
       ],
    //   handlers: {'emoji': function() {}}
     },
     
    // autoLink: true,

    mention: {
      allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
      mentionDenotationChars: ["@", "#"],
      source: (searchTerm:any, renderList:any, mentionChar:any) => {
        let values;

        if (mentionChar === "@") {
          values = this.atValues;
        } else {
          values = this.hashValues;
        }
        
        if (searchTerm.length === 0) {
          renderList(values, searchTerm);
        } else {
          const matches = [];
          for (var i = 0; i < values.length; i++)
            if (~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase())) matches.push(values[i]);
          renderList(matches, searchTerm);
        }
      },
    },
    "emoji-toolbar": true,
    "emoji-textarea": false,
    "emoji-shortname": true,
  }
 
  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public blogService: blogService,
    public msgService: MsgService,
    
  ) {
    this.addBlogInfo = new blogInfo({});
    this.imageUrl=environment.ImgUrl;
    this.blogid=this.activatedRoute.snapshot.params['id']
  }

  ngOnInit(): void {
    this.date=new Date();
    this.getBlogById()
  }

  getBlogById(){
    this.blogService.getById(this.blogid).subscribe(
      (data:any)=>{
        console.log('the data is>>>',data)
        this.addBlogInfo=data;
      },error=>{
        this.msgService.showWarning('Error Fetching Data')
      }
    )
  }

  submit() {
   

    this.blogService.upload(this.addBlogInfo, this.filesToUpload).subscribe(
      (data: any) => {
        this.msgService.showSuccess('Data Uploaded succesfully');
        this.router.navigate(['/dashboard/list-blog']);
        
     
      }, error => {
        this.msgService.showWarning('Data Upload Error! Please try again ')
      }

    )
  }

  fileChanged(e:any) {
    this.fileno = 0;
    this.urls = [];
    this.filesToUpload = [];

    var selectedImages = e.target.files;
    for (let i = 0; i < selectedImages.length; i++) {
      var mimeType = selectedImages[i].type;
      var image = mimeType.split("/")[0];
      var selectedFile=[];
     selectedFile = selectedImages[i];
      if (image == 'image') {
        this.filesToUpload.push(selectedFile)
      }
    }
    if (this.filesToUpload) {
      this.fileno = this.filesToUpload.length;
 
      this.imageSelected = true;
      if (this.fileno > 1) {
        alert('You cannot select files maximum 1. Please Upload Again');
        this.filesToUpload = [];
        this.urls = [];
        this.imageSelected = false;
      } else {
        for (let file of this.filesToUpload) {
          let reader = new FileReader();
          reader.onload = (e: any) => {
            this.urls.push(e.target.result);
          }
          reader.readAsDataURL(file);
        }
      }
    }
  }
  removeUploadedFile() {
    this.filesToUpload = [];
    this.urls = [];
    this.fileno = 0;
    this.imageSelected = false;
  }
  removeNew(i:any) {
    this.filesToUpload.splice(i, 1);
    this.urls.splice(i, 1);
    this.fileno = this.fileno - 1;
    if (this.fileno != 0) {
      this.imageSelected = true;
    } else {
      this.imageSelected = false;
    }
  }


}

export class setupsInfo {
    company_name: String;
    company_number: Number;
    address: String;
    email: String;
    copyright_year: Number;
    facebook_link: String;
    instagram_link: String;
    twitter_link: String;
    youtube_link: String;
    terms: String;
    policies: String;
    brief_about_us: String;
    detail_about_us: String;
    logo: String;
    meta_keywords: String;
    meta_description: String;
    site_title: String;
    site_description: String;
    copyrightNotice:String;
    cookiePolicy:String;
  
    constructor(obj:any){
        this.company_name=obj.company_name||'';
        this.company_number=obj.company_number||'';
        this.address=obj.address||'';
        this.email=obj.email||'';
        this.copyright_year=obj.copyright_year||'';
       

        this.facebook_link=obj.facebook_link||'';
        this.instagram_link=obj.instagram_link||'';
        this.twitter_link=obj.twitter_link||'';
        this.youtube_link=obj.youtube_link||'';
        this.terms=obj.terms||'';
        this.policies=obj.policies||'';
        this.brief_about_us=obj.brief_about_us||'';
        this.detail_about_us=obj.detail_about_us||'';
        this.logo=obj.logo||'';
        this.meta_keywords=obj.meta_keywords||'';

        this.meta_description=obj.meta_description||'';
        this.site_title=obj.site_title||'';
        this.site_description=obj.site_description||'';
        this.copyrightNotice=obj.copyrightNotice||'';
        this.cookiePolicy=obj.cookiePolicy||'';
        
    }
  }
export class testimonialsInfo {
    message:String;
    star:Number;
    name:String;
    image:String;
    
    
    
  
    constructor(obj:any){
        this.name=obj.name||'';
        this.message=obj.message||'';

        this.star=obj.star||'';
        this.image=obj.image||'';
       
        
    }
  }
import { Component, OnInit, ViewChild, HostListener, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { pricingCategoryInfo } from '../models for dashboard/pricingcategory.model';
import { pricingCategoryService } from '../services/pricingcategories.service';
import { MdbTablePaginationComponent, MdbTableDirective } from 'angular-bootstrap-md';
import { MsgService } from 'src/app/shared/service/msg.service';
import { specialPricingService } from '../services/specialPricing.service';
import { specialPricingInfo } from '../models for dashboard/specialPricing.model';

@Component({
  selector: 'app-specialpricing',
  templateUrl: './specialpricing.component.html',
  styleUrls: ['./specialpricing.component.scss']
})
export class SpecialpricingComponent implements OnInit,AfterViewInit {
  @ViewChild(MdbTablePaginationComponent, { static: true }) mdbTablePagination: MdbTablePaginationComponent;
  @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective
 
  elements: any = [];
  previous: any = [];
  headElements = ['S.No.', 'Name', 'Amount', 'Description', 'Main', 'View'];

  dataReceived:Boolean;
  addBackgroundShow:Boolean=false;
  submitting:Boolean=false;
  pricingCategories;
  noFileToUpload=[];
 

  constructor(
    private cdRef: ChangeDetectorRef,
    public specialPricingService:specialPricingService,
    public msgService:MsgService
  ) {
    this.dataReceived=false;
    this.pricingCategories=new specialPricingInfo({})
   }

  ngOnInit(): void {
    this.mdbTable.setDataSource(this.elements);
    this.elements = this.mdbTable.getDataSource();
    this.previous = this.mdbTable.getDataSource();
    this.getBrands();
  }
 
  getBrands(){
    this.elements=[];
    this.specialPricingService.getall().subscribe(
      (data:any)=>{
        this.msgService.showSuccess('Data Loading Success!!!');
        if(data.length !=0){
          
          // this.addAds.id=parseInt(data[0].id)+1;
          this.elements=data;
          this.mdbTable.setDataSource(this.elements);
          this.elements = this.mdbTable.getDataSource();
          this.previous = this.mdbTable.getDataSource();
        }
      },
      (error:any)=>{
        this.msgService.showWarning('Data loading error !!!')
      }
    )
  }
  ngAfterViewInit() {
    this.mdbTablePagination.setMaxVisibleItemsNumberTo(50);

    this.mdbTablePagination.calculateFirstItemIndex();
    this.mdbTablePagination.calculateLastItemIndex();
    this.cdRef.detectChanges();
  }
  showAddCategoriesModal(){
    this.addBackgroundShow=true;
  }
  addCategoriesModalClose(){
    this.addBackgroundShow=false;
  }
  submit(){
    this.submitting=true;
    this.specialPricingService.add(this.pricingCategories).subscribe(
      (data:any)=>{
        this.msgService.showSuccess('Thankyou For uploading.');
        this.getBrands();
        this.filter()
      },error=>{
        this.msgService.showWarning('Error ! Please try again later');
        this.filter()
      }
    )
  }
  filter(){
    this.submitting=false;
    this.addBackgroundShow=false;
    this.pricingCategories=new specialPricingInfo({})

  }
  edit(val1:any, val2:any){

  }
  
  
 

}

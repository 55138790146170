export class sampleRequestInfo {
    name:String;
    email:String;
    sampleName:String;
    phoneNumber:Number;
  
    constructor(obj:any){
        this.name=obj.name||'';
        this.email=obj.email||'';
        this.sampleName=obj.sampleName||'';
        this.phoneNumber=obj.phoneNumber||'';
        
    }
  }
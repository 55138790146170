import { Component, OnInit, ViewChild, HostListener, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { pricingCategoryInfo } from '../models for dashboard/pricingcategory.model';
import { pricingCategoryService } from '../services/pricingcategories.service';
import { MdbTablePaginationComponent, MdbTableDirective } from 'angular-bootstrap-md';
import { MsgService } from 'src/app/shared/service/msg.service';

@Component({
  selector: 'app-pricing-categories',
  templateUrl: './pricing-categories.component.html',
  styleUrls: ['./pricing-categories.component.scss']
})
export class PricingCategoriesComponent implements OnInit,AfterViewInit {
  @ViewChild(MdbTablePaginationComponent, { static: true }) mdbTablePagination: MdbTablePaginationComponent;
  @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective
 
  elements: any = [];
  previous: any = [];
  headElements = ['S.No.', 'Category Name'];

  dataReceived:Boolean;
  addBackgroundShow:Boolean=false;
  submitting:Boolean=false;
  pricingCategories;
  noFileToUpload=[];
  deleteAsk:Boolean=false;
  //for temporaray
 
  deleteid:any;

  constructor(
    private cdRef: ChangeDetectorRef,
    public pricingCategoryService:pricingCategoryService,
    public msgService:MsgService
  ) {
    this.dataReceived=false;
    this.pricingCategories=new pricingCategoryInfo({})
   }

  ngOnInit(): void {
    this.mdbTable.setDataSource(this.elements);
    this.elements = this.mdbTable.getDataSource();
    this.previous = this.mdbTable.getDataSource();
    this.getBrands();
  }
 
  getBrands(){
    this.elements=[];
    this.pricingCategoryService.getall().subscribe(
      (data:any)=>{
        this.msgService.showSuccess('Data Loading Success!!!');
        if(data.length !=0){
          
          // this.addAds.id=parseInt(data[0].id)+1;
          this.elements=data;
          this.mdbTable.setDataSource(this.elements);
          this.elements = this.mdbTable.getDataSource();
          this.previous = this.mdbTable.getDataSource();
        }
      },
      (error:any)=>{
        this.msgService.showWarning('Data loading error !!!')
      }
    )
  }
  ngAfterViewInit() {
    this.mdbTablePagination.setMaxVisibleItemsNumberTo(50);

    this.mdbTablePagination.calculateFirstItemIndex();
    this.mdbTablePagination.calculateLastItemIndex();
    this.cdRef.detectChanges();
  }
  showAddCategoriesModal(){
    this.addBackgroundShow=true;
  }
  addCategoriesModalClose(){
    this.addBackgroundShow=false;
  }
  submit(){
    this.submitting=true;
    this.pricingCategoryService.upload(this.pricingCategories,this.noFileToUpload).subscribe(
      (data:any)=>{
        this.msgService.showSuccess('Thankyou For uploading.');
        this.getBrands();
        this.filter()
      },error=>{
        this.msgService.showWarning('Error ! Please try again later');
        this.filter()
      }
    )
  }
  filter(){
    this.submitting=false;
    this.addBackgroundShow=false;
    this.pricingCategories=new pricingCategoryInfo({})

  }
  askDelete(i:any, data:any){
    this.deleteAsk=i;
    this.deleteid=data._id;
  }
  deleteModalClose(){
    this.deleteAsk=false;    
    this.deleteid=null;
  }
  
  delete(){
    this.pricingCategoryService.remove(this.deleteid).subscribe(
      (data:any)=>{
        
        this.msgService.showSuccess('Data deleted successfully');
       
        this.getBrands();
        this.deleteAsk=false;    
      },
      error=>{
        this.msgService.showWarning('Error deleting data')
      }
    )
  }

}

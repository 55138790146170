import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  user:String;
  loggedIn:boolean=false;
  logIn;
 
  constructor() {
    this.user=JSON.parse(localStorage.getItem('user')||'{}');
    this.logIn=localStorage.getItem('loggedInUser');
    if(this.user!=null){
      if(this.logIn==='true'){
        this.loggedIn=true;
      }else{
        this.loggedIn=false;
      }
    }else{
      this.loggedIn=false;
    }
   }

  ngOnInit(): void {
  }
  ngDoCheck(){
    this.user=JSON.parse(localStorage.getItem('user')||'{}');
    this.logIn=localStorage.getItem('loggedInUser');
    if(this.user!=null){
      if(this.logIn==='true'){
        this.loggedIn=true;
      }else{
        this.loggedIn=false;
      }
    }else{
      this.loggedIn=false;
    }
  }
  

}

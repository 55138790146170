<div class="services_page mb-5">
    <div class="container">
        <div class="row mb-5">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <div class="services_page_title">
                    <h4 class="text-center">OUR <span>SERVICES</span></h4>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                <div class="our_services_details py-4 px-5">
                    <h5 class="text-center">CDR Writing</h5>
                    <h6 class="text-center mt-4">Lorem Ipsum Dolor sit amet Set ersoicitas unde iste natis error sit attem Lorem Ipsum Dolor 
                        sit amet Set ersoicitas unde iste natis er</h6>
                </div>
                <div class="our_services_image text-center">
                    <img src="./../../../assets/images/report icon.png"  alt="thumbnail" >
                </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                <div class="our_services_details py-4 px-5">
                    <h5 class="text-center">Career Episode Writing</h5>
                    <h6 class="text-center mt-4">Lorem Ipsum Dolor sit amet Set ersoicitas unde iste natis error sit attem Lorem Ipsum Dolor 
                        sit amet Set ersoicitas unde iste natis er</h6>
                </div>
                <div class="our_services_image text-center">
                    <img src="./../../../assets/images/report icon.png"  alt="thumbnail" >
                </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                <div class="our_services_details py-4 px-5">
                    <h5 class="text-center">Summary Statement</h5>
                    <h6 class="text-center mt-4">Lorem Ipsum Dolor sit amet Set ersoicitas unde iste natis error sit attem Lorem Ipsum Dolor 
                        sit amet Set ersoicitas unde iste natis er</h6>
                </div>
                <div class="our_services_image text-center">
                    <img src="./../../../assets/images/report icon.png"  alt="thumbnail" >
                </div>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                <div class="our_services_details py-4 px-5">
                    <h5 class="text-center">ACS RPL Report Writing</h5>
                    <h6 class="text-center mt-4">Lorem Ipsum Dolor sit amet Set ersoicitas unde iste natis error sit attem Lorem Ipsum Dolor 
                        sit amet Set ersoicitas unde iste natis er</h6>
                </div>
                <div class="our_services_image text-center">
                    <img src="./../../../assets/images/report icon.png"  alt="thumbnail" >
                </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                <div class="our_services_details py-4 px-5">
                    <h5 class="text-center">KA02 Report Writing</h5>
                    <h6 class="text-center mt-4">Lorem Ipsum Dolor sit amet Set ersoicitas unde iste natis error sit attem Lorem Ipsum Dolor 
                        sit amet Set ersoicitas unde iste natis er</h6>
                </div>
                <div class="our_services_image text-center">
                    <img src="./../../../assets/images/report icon.png"  alt="thumbnail" >
                </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                <div class="our_services_details py-4 px-5">
                    <h5 class="text-center">Check & Remove Plagarism</h5>
                    <h6 class="text-center mt-4">Lorem Ipsum Dolor sit amet Set ersoicitas unde iste natis error sit attem Lorem Ipsum Dolor 
                        sit amet Set ersoicitas unde iste natis er</h6>
                </div>
                <div class="our_services_image text-center">
                    <img src="./../../../assets/images/review icon.png"  alt="thumbnail" >
                </div>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                <div class="our_services_details py-4 px-5">
                    <h5 class="text-center">ACS RPL Report Reviewing</h5>
                    <h6 class="text-center mt-4">Lorem Ipsum Dolor sit amet Set ersoicitas unde iste natis error sit attem Lorem Ipsum Dolor 
                        sit amet Set ersoicitas unde iste natis er</h6>
                </div>
                <div class="our_services_image text-center">
                    <img src="./../../../assets/images/review icon.png"  alt="thumbnail" >
                </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                <div class="our_services_details py-4 px-5">
                    <h5 class="text-center">KA02 Report Reviewing</h5>
                    <h6 class="text-center mt-4">Lorem Ipsum Dolor sit amet Set ersoicitas unde iste natis error sit attem Lorem Ipsum Dolor 
                        sit amet Set ersoicitas unde iste natis er</h6>
                </div>
                <div class="our_services_image text-center">
                    <img src="./../../../assets/images/review icon.png"  alt="thumbnail" >
                </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                <div class="our_services_details py-4 px-5">
                    <h5 class="text-center">Individual Conslting</h5>
                    <h6 class="text-center mt-4">Lorem Ipsum Dolor sit amet Set ersoicitas unde iste natis error sit attem Lorem Ipsum Dolor 
                        sit amet Set ersoicitas unde iste natis er</h6>
                </div>
                <div class="our_services_image text-center">
                    <img src="./../../../assets/images/services_icon_3.png"  alt="thumbnail" >
                </div>
            </div>
        </div>
    </div>
</div>
<div class="services_page_form ">
    <div class="container">
        <div class="row">
            <div class="col-xl-8 col-lg-8 col-md-6 col-sm-12" id="service_change_padding">
                <div class="services_page_form_title py-5">
                    <h4 >GET <span>FREE CONSULTATION</span> WITH OUR EXPERTS.</h4>
                    <p>STOP ! wasting time and money searching agencies. At CDR Writing Australia, we offer free consultation to our 
                        esteemed cliens with with one of our expert on field. Our experts will gurantee your results to be refined, 
                        genuine and unique and plagarism free.</p>
                </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12" id="service_change_padding2">
                <div class="service_form">
                    <div class="service_form_head_box">
                        <h4 class="text-center py-3">FREE CONSULTATION</h4>
                    </div>
                    <div class="service_page_form_box px-3 py-4">
                        <form class="text-center form-group " #dataForm="ngForm">
                            <div class="mb-4">
                                <input class="form-control" type="text" placeholder="Full Name" [(ngModel)]="consultationForm.name"
                                    name="Name" #nameis="ngModel" required>                                
                                <input class="form-control " type="text" placeholder="Email" [(ngModel)]="consultationForm.email"
                                name="Email" #emailis="ngModel" required email >                                   
                                <input class="form-control" type="number" placeholder="Contact Number" [(ngModel)]="consultationForm.phone"
                                    name="Phone" #phoneis="ngModel" required>                                
                                <input class="form-control" type="datetime-local" placeholder="Prefered Date & Time" [(ngModel)]="consultationForm.date"
                                    name="Date" #dateis="ngModel" required>                                
                                
                            </div>      
                            <div class="service_form_button text-center">
                                <button class="button_faq" *ngIf="!submitting" (click)="submitConsultation()">Submit</button>
                                <button class="button_faq" *ngIf="submitting" disabled>Submitting...</button>
                            </div>         
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
export class blogInfo {
    name:String;
    slug: String;
    title: String;
    description: String;
    description2: String;
    featured: String;
    popular: String;
    author_id: String;
    category_id: Number;
    seo_keyword: String;
    seo_description: String;
    date:Object;
    image:String;
    
    
  
    constructor(obj:any){
        this.name=obj.name||'';
        this.slug=obj.slug||'';

        this.title=obj.title||'';
        this.description=obj.description||'';
        this.description2=obj.description2||'';
        this.featured=obj.featured||'';
        this.popular=obj.popular||'';
        this.author_id=obj.author_id||'';
        this.category_id=obj.category_id||'';
        this.seo_keyword=obj.seo_keyword||'';
        this.seo_description=obj.seo_description||'';
        this.date=obj.date||'';
        this.image=obj.image||'';
        
    }
  }
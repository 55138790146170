import { Component, OnInit } from '@angular/core';
import { consultationForm } from '../models/consultation.model';
import { Router } from '@angular/router';
import { consultationService } from 'src/app/dashboard/services/consultation.service';
import { MsgService } from 'src/app/shared/service/msg.service';

@Component({
  selector: 'app-anzsco-code',
  templateUrl: './anzsco-code.component.html',
  styleUrls: ['./anzsco-code.component.scss']
})
export class AnzscoCodeComponent implements OnInit {
  submitting:Boolean=false;
  consultationForm;
  constructor(
    public router:Router,
    public msgService:MsgService,
    public consultationService:consultationService
  ) { 
    this.consultationForm=new consultationForm({});
  }

  ngOnInit(): void {
  }
  submitConsultation(){
    this.submitting=true;
    this.consultationService.add(this.consultationForm).subscribe(
      (data:any)=>{
        this.msgService.showSuccess('Thankyou for your inquiry. We will get back to you soon.');
        this.consultationForm=new consultationForm({});
        this.submitting=false;
      },error=>{
        this.msgService.showWarning('Error! Please try again later.')
      }
    )
  }

}

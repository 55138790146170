export class specialPricingInfo {
    name:String;
    amount:Number;
    description:String;
    ismain:Boolean;
  
    constructor(obj:any){
        this.name=obj.name||'';
        this.amount=obj.amount||'';

        this.description=obj.description||'';
        this.ismain=obj.ismain||'';
        
    }
  }
export class userQuestionForm {
    question:string;
    email:string;
    termsAccepted:Boolean;
    

    cyear:Number;
    cmonth:Number;
    cday:Number;
  
  constructor(obj:any){
      this.question=obj.question||'';
      this.email=obj.email||'';
      this.termsAccepted=obj.termsAccepted||'';
      this.cyear=obj.cyear||'';
      this.cmonth=obj.cmonth||'';
      this.cday=obj.cday||'';
  }
}
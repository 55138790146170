import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './../../shared/service/baseService';
import { Observable } from 'rxjs';
import { sampleRequestInfo } from '../models for dashboard/sampleReq.model';


@Injectable()
export class sampleReqService extends BaseService {
    url:any;
    constructor(public http: HttpClient) {
        super();
    }
    add(data: sampleRequestInfo) {
        return this.http.post(this.baseUrl + 'sampleReq', data, this.getOptions());
    }
    update(data: any) {
        return this.http.put(this.baseUrl + 'sampleReq/' + data._id, data, this.getOptionsWithToken());
    }
    getall() {
        return this.http.get(this.baseUrl + 'sampleReq/myOwnData', this.getOptionsWithToken());
    }
    getByDate(date:any) {
        return this.http.get(this.baseUrl + 'sampleReq/myOwnData/date/' + date, this.getOptionsWithToken());
    }

    remove(id:any) {
        return this.http.delete(this.baseUrl + 'sampleReq/' + id, this.getOptionsWithToken());
    }
    getById(id:any) {
        return this.http.get(this.baseUrl + 'sampleReq/' + id, this.getOptionsWithToken());
    }
    filterByYear(data:any) {
        return this.http.post(this.baseUrl + 'sampleReq/filterByYear/', data, this.getOptionsWithToken());
    }

    
}
<div class="contact_us_page">
  <div class="container">
    <div class="row mb-3">
      <div class="col-xl-12 col-lg-12 col-md-12 col-xs-12">
        <div class="contact_us_title">
          <h4 class="text-center">CONTACT <span>US</span></h4>
        </div>
      </div>
    </div>
    <div class="row mb-4">
      <div class="col-xl-2 col-lg-2 col-md-6 col-xs-12 col-sm-6" id="padding_edit1">
        <div class="address_icon">
          <i class="fas fa-map-marked-alt"></i>
        </div>
      </div>
      <div class="col-xl-4 col-lg-4 col-md-6 col-xs-12 col-sm-6" id="padding_edit2">
        <div class="address_det p-4">
          <h5>ADDRESS :</h5>
          <h6>Dublin street Edinburgh Sydney, NSW, Australia 2026</h6>
        </div>
      </div>
      <div class="col-xl-2 col-lg-2 col-md-6 col-xs-12 col-sm-6" id="padding_edit1">
        <div class="address_icon">
          <i class="far fa-question-circle"></i>
        </div>
      </div>
      <div class="col-xl-4 col-lg-4 col-md-6 col-xs-12 col-sm-6" id="padding_edit2">
        <div class="address_det p-4">
          <h5>CONTACT :</h5>
          <h6>krijupatel01@gmail.com <br /></h6>
        </div>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-xl-12 col-lg-12 col-md-12 col-xs-12">
        <div class="google_map">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d2233.59970461774!2d-3.1942343070629167!3d55.95630964176511!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2snp!4v1616732967336!5m2!1sen!2snp"
            width="100%"
            height="450"
            style="border: 0"
            allowfullscreen=""
            aria-hidden="false"
            tabindex="0"
          ></iframe>
        </div>
      </div>
    </div>
    <div class="row mt-5 mb-5">
      <div class="col-xl-6 col-lg-6 col-md-6 col-xs-12 col-sm-12">
        <div class="get_in_touch_title">
          <h4 class="text-center mb-5"><span>GET IN TOUCH</span> WITH US</h4>
          <h6 class="mb-5">
            If you have any question or inquiry please use the form below to get
            in touch wiht us. We look forward to hearing from you.
          </h6>
        </div>
        
      </div>
      <div class="col-xl-6 col-lg-6 col-md-6 col-xs-12 col-sm-12">
        <form class="text-center form-group" #dataForm="ngForm">
          <div class="get_in_touch_form mb-5 px-3 py-4">
            <input
              class="form-control"
              type="text"
              placeholder="Fullname"
              [(ngModel)]="contactus.name"
              name="Name"
              #nameis="ngModel"
              required
            />
           
            <input
              class="form-control"
              type="text"
              placeholder="Email"
              [(ngModel)]="contactus.email"
              name="Email"
              #emailis="ngModel"
              required
              email
            />
           
            <input
              class="form-control"
              type="number"
              placeholder="Contact no"
              [(ngModel)]="contactus.phone"
              name="Phone"
              #phoneis="ngModel"
              required
            />
           
            <textarea
              class="form-control"
              placeholder="Message"
              [(ngModel)]="contactus.message"
              name="message"
              #messageis="ngModel"
              required
            ></textarea>
           
          </div>
          <div class="faq_form_button text-center mt-2">
            <button class="button_faq" *ngIf="!submitting" (click)="submitContactUs()">Submit</button>
            <button class="button_faq" *ngIf="submitting" disabled>
              Submitting...
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

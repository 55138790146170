<div class="home-content1 ">
    <div class="home1">
      <div class="container">
        <div class="row">
          <h1>Lorem <span class="top_text_color">ipsum dolor</span> sit amet lorem ipsum</h1>
        </div>
        <div class="row px-5 login_form">
            <div class="col-md-12 col-xs-12 col-sm-12 col-lg-12 " >
                <form class="m-auto w-50 px-5 py-2" #dataForm="ngForm">
                    <div class=" form form-group ">
                      <label for="Username">Email</label>
                      <input class="form-control" type="text" placeholder="Email" [(ngModel)]="userform.email"
                        name="Email" #emailis="ngModel">
                        <div class="danger" *ngIf="emailis.errors && emailis.dirty">
                          <div *ngIf="emailis.errors.required">
                          required field*
                          </div>
                          <div *ngIf="emailis.errors.email">
                          not a valid email
                          </div>
                        </div> 
                      <label for="password">Password</label>
                      <input class="form-control" type="password" placeholder="Password" [(ngModel)]="userform.password"
                        name="Password" #passwordis="ngModel">
                        <div class="danger" [hidden]="passwordis.valid || passwordis.pristine">
                          *required field
                          </div>
                    </div>
                    
                    <button type="submit" class="btn btn-danger" (click)="login()">Sign in</button>
                    <p> <a [routerLink]="['/auth/forgot-password']">Forgot Password?</a></p>
                  </form>
                                    
            </div>
        </div>   
      </div>
    </div>   
  </div>
<div class="footer">
  <div class="footer_image">
    <div class="container">
      <div class="row">
        <div class="first_col col-xs-12 col-sm-12 col-md-6 col-lg-3">
          <div class="footer_title">
            <h4>IMP LINKS</h4>
          </div>
          <div class="footer_links pt-3">
            <ul>
              <li>Contact Us</li>
              <li>Pricing</li>
              <li>How it Works</li>
              <li>FAQ</li>
              <li>
                <a href="#/terms">Terms of Use</a>
              </li>
            </ul>
          </div>
        </div>
        <div class="second_col col-xs-12 col-sm-12 col-md-6 col-lg-3">
          <div class="footer_title">
            <h4>SERVICES</h4>
          </div>
          <div class="footer_links pt-3">
            <ul>
              <li>CDR Report Wrting Help</li>
              <li>Career Episode Writing Help</li>
              <li>Summary Statement Writing Help</li>
              <li>CDR Report Writing Help</li>
              <li>RFL Report Writing Help</li>
              <li>KA02 Report Writing Help</li>
            </ul>
          </div>
        </div>
        <div class="third_col col-xs-12 col-sm-12 col-md-6 col-lg-3">
          <div class="footer_title">
            <h4>HELP</h4>
          </div>
          <div class="footer_links pt-3">
            <ul>
              <li>About Us</li>
              <li>ANZSCO CODE</li>
              <li>CDR Rejection</li>
              <li>Request Sample</li>
            </ul>
          </div>
        </div>
        <div class="fourth_col col-xs-12 col-sm-12 col-md-6 col-lg-3">
          <div class="footer_title">
            <h4>CONTACT US</h4>
          </div>
          <div class="footer_links pt-3">
            <ul>
              <li>Dublin street Edinburgh Sydney, NSW, Australia 2026</li>
              <li>(03) 5324 7572</li>
              <li>
                <img
                  src="./../../../assets/images/cdr logo 1.png"
                  class="img-fluid"
                  alt="Responsive image"
                />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="footer_bottom w-100">
  <div class="row">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
      <div class="mt-3 ">
        <h5 class="text-center">CDR Writing Service 2021</h5>
      </div>
    </div>
  </div>
</div>

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-blog-by-name',
  templateUrl: './blog-by-name.component.html',
  styleUrls: ['./blog-by-name.component.scss']
})
export class BlogByNameComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

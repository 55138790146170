<div class="container terms-container">
  <h3 class="mt-5 mb-3">Terms of Use</h3>
  <p>CDR Writing Service is delighted to welcome you.</p>

  <p>
    The rules and regulations for using CDR Writing Service, located at
    CDRWritingService.com, are outlined in these terms and conditions.
  </p>
  <p>
    We believe that by using this page, you agree to these terms and conditions.
    If you do not agree to all of the terms and conditions mentioned on this
    page, do not use CDRWritingService.com
  </p>
  <p>
    These Terms and Conditions, Privacy Statement and Disclaimer Notice, and all
    Agreements are governed by the following terminology: "Client," "You," and
    "Your," apply to you, the individual who logs on to this website and agrees
    to the Company's terms and conditions. Our Company is referred to as "The
    Company," "Ourselves," "We," "Ours," and "Us." Both the Client and ourselves
    are referred to as "Party," "Parties," or "Us."
  </p>
  <p>
    Any use of the previous terminology, as well as other words in the singular,
    plural, capitalization, and/or he/she or they, is taken to mean the same
    thing.
  </p>
  <p>
    CDR Writing Service reserves the right to amend, alter, add, or delete parts
    of these Terms of Use at any time, at its absolute discretion. It is your
    responsibility to review these Terms of Use for any updates on a regular
    basis. If you continue to use the Site after the updates are posted, you are
    indicating that you approve and consent to the changes.
  </p>
  <p>
    CDR Writing Service grants you an intimate, non-exclusive, non-transferable,
    restricted privilege to access and use the Site as long as you comply with
    these Terms of Use.
  </p>
  <h3 class="mt-5 mb-3">Disclaimer</h3>
  <p>
    CDRWritingService.com only supports professional engineers prepare CDR
    reports, career episode reports, and summary statement reports for skill
    assessments based on their real-world experience and expertise.
  </p>
  <p>
    Provided CDR samples are only for guidance and hint purpose. These samples
    are meant to be used as analysis and reference materials. Don't try and copy
    and paste it in its entirety.
  </p>
  <p>
    Engineers Australia or any other certification evaluating authority are not
    associated with us and also we are not affiliated with them.
  </p>

  <h3 class="mt-5 mb-3">Cookies and Other identifiers</h3>
  <p>
    You can choose to save your name, email address, and website in cookies if
    you leave a comment on our page. These are for your convenience so you don't
    have to enter your information again when you leave another comment.
  </p>
  <p>
    If you log in to this site with an account, we'll set a temporary cookie to
    see if your browser accepts cookies. This cookie contains no personal
    information and is deleted when your browser is closed.
  </p>

  <h3 class="mt-5 mb-3">How long do we keep your information?</h3>
  <p>
    If you leave a message, both the comment and the metadata associated with it
    are saved forever. This is so that any subsequent feedback will be
    recognized and approved immediately rather than being held in a moderation
    queue.
  </p>
  <h3 class="mt-5 mb-3">Copyrights, Trademarks, Patents</h3>
  <p>
    All Rights Reserved. Copyright 2021 CDR Writing Service. Text, photographs,
    graphics, sound files, animation files, video files, and their arrangement
    on CDR Writing Service websites are all protected by copyright and other
    intellectual property laws. These objects may not be edited or reposted, nor
    may they be copied for commercial use or distribution.
  </p>
  <h3 class="mt-5 mb-3">Limitation of liabilities</h3>
  <p>
    We are not responsible for any interruptions or delays in access to the
    Site, but we are always available to help. We don't take responsibility for
    any harm or data loss on your computer, server, or network.
  </p>
  <p>
    We cannot claim that access to our Website will be error-free; however, our
    committed professionals will do their utmost to ensure that it runs
    smoothly. We reserve the right to disable access when the website is being
    maintained or updated. We are not liable for any loss of access to our
    website caused by connection or equipment failures beyond our control.
  </p>
</div>

<div class="cdr_rejection">
  <div class="container">
    <div class="row cdr_rejection_first mb-3">
      <div class="col-xl-7 col-lg-7 col-md-7 col-sm-12 px-5">
        <div class="cdr_rejection_first_title mt-3">
          <h4>WHY <span>CDR REJECTION</span> TAKES PLACE ?</h4>
        </div>
        <div class="cdr_rejection_first_desc pt-4">
          <h6 class="mb-4">
            Has your CDR been rejected? Or are you afraid that your CDR may be
            rejected?
          </h6>
          <p>
            CDR rejection is common, and there is no need to panic. Even
            engineers with years of experience and accomplishment failed in CDR
            assessment. What happens if CDR gets rejected? The rejection doesn’t
            mean that the candidates don’t have the capability and
            qualification. It’s because of not following EA guidelines for
            writing a CDR. of common mistakes made by applicants when writing a
            CDR application for an Australian Skill Assessment Engineer.
          </p>
        </div>
      </div>
      <div class="col-xl-5 col-lg-5 col-md-5 col-sm-12">
        <div class="cdrRejection_image1">
          <img
            src="./../../../assets/images/rectangle216.png"
            alt="thumbnail"
            class="img-thumbnail"
            style="width: 300px; margin-left: 8rem"
          />
        </div>
        <div class="cdrRejection_image2">
          <img
            src="./../../../assets/images/Rectangle 215.png"
            alt="Responsive image"
          />
        </div>
        <div class="cdrRejection_image3">
          <img
            src="./../../../assets/images/image 7.png"
            class="img-fluid"
            alt="Responsive image"
          />
        </div>
      </div>
      <hr />
    </div>
    <div class="row mt-5 px-3">
      <div class="col-xl-5 col-lg-5 col-md-5 col-sm-12" id="cdr_padding_edit1">
        <div class="cdr_rejection_section_img">
          <img
            src="./../../../assets/images/image 2.png"
            class="img-thumbnail"
          />
        </div>
      </div>
      <div class="col-xl-7 col-lg-7 col-md-7 col-sm-12" id="cdr_padding_edit2">
        <div class="cdr_rejection_section_desc py-3 px-3">
          <h4 class="mb-3">
            1.
            <span class="theme-color">Plagarism</span>
          </h4>
          <p>
            Plagiarism is a critical reason for the rejection of your CDR
            report. CDR samples available in different websites are only for
            reference purpose. Never copy and paste the project report from the
            CDR sample or from the internet. Whether you do it intentionally or
            unintentionally, Engineers Australia will catch your plagiarized
            content and rejects your report. The CDR report is meant to be an
            original work based on your own profile and project. So, to avoid
            plagiarism and rejection just read the sample, learn from it and
            write in a similar way.
          </p>
        </div>
      </div>
    </div>
    <div class="row mt-5 px-3">
      <div class="col-xl-7 col-lg-7 col-md-7 col-sm-12" id="cdr_padding_edit1">
        <div class="cdr_rejection_section_desc py-3 px-3">
          <h4 class="mb-3">
            2. <span class="theme-color">Use </span> Of Excessive
            <span class="theme-color">Technical </span> Details
          </h4>
          <p>
            It is okay to write some technical detail information on your
            report. But too many technical details like tables, excessive
            calculations, charts, photos, etc. on each report can degrade your
            CDR report. A CDR report is an official document which is meant to
            be written by a professional without excessive use of technical
            details. That’s why a CDR report should be in point information
            rather than long and elaborated.
          </p>
        </div>
      </div>
      <div class="col-xl-5 col-lg-5 col-md-5 col-sm-12" id="cdr_padding_edit2">
        <div class="cdr_rejection_section_img">
          <img
            src="./../../../assets/images/image 3.png"
            class="img-thumbnail"
          />
        </div>
      </div>
    </div>
    <div class="row mt-5 px-3">
      <div class="col-xl-5 col-lg-5 col-md-5 col-sm-12" id="cdr_padding_edit1">
        <div class="cdr_rejection_section_img">
          <img
            src="./../../../assets/images/image 4.png"
            class="img-thumbnail"
          />
        </div>
      </div>
      <div class="col-xl-7 col-lg-7 col-md-7 col-sm-12" id="cdr_padding_edit2">
        <div class="cdr_rejection_section_desc py-3 px-3">
          <h4 class="mb-3">
            3. <span class="theme-color">Career </span>Episode
            <span class="theme-color"> Too Technical</span>
          </h4>
          <p>
            Each career episode demonstrates the application of engineering
            knowledge and skills in the chosen engineering discipline. According
            to the EA it is strictly mentioned that the career episode must not
            be too technical. A career episode is where you discuss your project
            or problem and your approach to that problem and results. If some
            necessary calculation is involved then you can include them in your
            CDR Report. But the diagrams, tables and excessive calculations must
            be avoided. Engineer Australia wants to check solving ability and
            your practical skill because the theory knowledge of yours can be
            already known via your educational qualifications.
          </p>
        </div>
      </div>
    </div>
    <div class="row mt-5 px-3">
      <div class="col-xl-7 col-lg-7 col-md-7 col-sm-12" id="cdr_padding_edit1">
        <div class="cdr_rejection_section_desc py-3 px-3">
          <h4 class="mb-3">4. <span class="theme-color">Word</span> Count</h4>
          <p>
            There is a specific rule for the number of words that should be
            written in each section of CDR reports. The words limit for each
            Career episode is more than 1000 words and do not exceed 2500 words.
            Providing extra details and making it too long can lead your CDR to
            rejection. So, you need to include only the essential information
            and address within a limited word which satisfies all the
            requirement of Engineer’s Australia.
          </p>
        </div>
      </div>
      <div class="col-xl-5 col-lg-5 col-md-5 col-sm-12" id="cdr_padding_edit2">
        <div class="cdr_rejection_section_img">
          <img
            src="./../../../assets/images/image 5.png"
            class="img-thumbnail"
          />
        </div>
      </div>
    </div>
    <div class="row mt-5 px-3">
      <div class="col-xl-5 col-lg-5 col-md-5 col-sm-12" id="cdr_padding_edit1">
        <div class="cdr_rejection_section_img">
          <img
            src="./../../../assets/images/image 6.png"
            class="img-thumbnail"
          />
        </div>
      </div>
      <div class="col-xl-7 col-lg-7 col-md-7 col-sm-12" id="cdr_padding_edit2">
        <div class="cdr_rejection_section_desc py-3 px-3">
          <h4 class="mb-3">
            5. <span class="theme-color">Lack</span> of
            <span class="theme-color">Creative Design</span> In The CDR
          </h4>
          <p>
            The high priority in CDR assessment is given to design activities.
            There is a high chance of your CDR report being positively assessed
            when you include the technical design activities in it. In the same
            way, lack of creative design may act as a huge impediment to the
            positive assessment of your CDR report. Hence explain the
            technological experience and creative engineering regarding the
            designing activities you have and make sure to highlight it as
            possible.
          </p>
        </div>
      </div>
    </div>
    <div class="row mt-5 px-3">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
        <div class="cdr_rejection_final">
          <h4 class="mb-3">Other Reason For CDR Rejection.</h4>
          <ul>
            <li>Improper/Technical Difficulties</li>
            <li>Absurd Selection Of Project</li>
            <li>Vague/ Wayward Summary Statement</li>
            <li>Not matching the provided guideline when writing report.</li>
            <li>Misguided CPD (Continuing Professional Development)</li>
            <li>Mention Only Your Experiences</li>
            <li>Not Mentioning the Problem Statements</li>
            <li>CDR In Different Language</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { assistanceService } from 'src/app/dashboard/services/assistance.service';
import { MsgService } from 'src/app/shared/service/msg.service';
import { assistanceForm } from '../models/assistance.model';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
assistantData;
submitting:Boolean=false;
noFileToUpload=[];

  constructor(
    public router:Router,
    public msgService:MsgService,
    public assistanceService:assistanceService
  ) { 
    this.assistantData= new assistanceForm({});

  }

  cards = [
    {
      name: 'John Doe',
      noOfStars: 5,
      message: 'This is a longer card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.',
      img: './../../../assets/images/client 2.png'
    },
    {
      name: 'John Doe',
      noOfStars: 5,
      message: 'This is a longer card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.',
      img: './../../../assets/images/client 1.png'
    },
    {
      name: 'John Doe',
      noOfStars: 5,
      message: 'This is a longer card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.',
      img: './../../../assets/images/client 2.png'
    },
    {
      name: 'John Doe',
      noOfStars: 5,
      message: 'This is a longer card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.',
      img: './../../../assets/images/client 1.png'
    },
    
    
  ];
  slides: any = [[]];
  chunk(arr: any, chunkSize:any) {
    let R = [];
    for (let i = 0, len = arr.length; i < len; i += chunkSize) {
      R.push(arr.slice(i, i + chunkSize));
    }
    return R;
  }
  ngOnInit() {
    this.slides = this.chunk(this.cards, 2);
  }
  submitAssistanceInfo(){
    
    this.submitting=true;
    this.assistanceService.add(this.assistantData).subscribe(
      (data:any)=>{
        this.submitting=false;        
        this.msgService.showSuccess("Thankyou for your query. We'll get back to you soon. ");
        this.assistantData= new assistanceForm({});
      },error=>{
        this.msgService.showWarning('Error ! Please try again later')
      }
    )
  }

}

export class contactUsForm {
    name:string;
    email:string;
    phone:String;
    message:String;   

    cyear:Number;
    cmonth:Number;
    cday:Number;
  
  constructor(obj:any){
      this.name=obj.name||'';
      this.email=obj.email||'';
      this.phone=obj.phone||'';
      this.message=obj.message||'';
      this.cyear=obj.cyear||'';
      this.cmonth=obj.cmonth||'';
      this.cday=obj.cday||'';
  }
}
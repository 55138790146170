import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { userQuestionService } from 'src/app/dashboard/services/userquestion.service';
import { MsgService } from 'src/app/shared/service/msg.service';
import { userQuestionForm } from '../models/userQuestion.model';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {
  userQuestion;
  submitting:Boolean=false;
  constructor(
    public router:Router,
    public msgService:MsgService,
    public userQuestionService:userQuestionService
  ) {
    this.userQuestion=new userQuestionForm({});
   }

  ngOnInit(): void {
  }
  submitForm(){
    this.userQuestionService.add(this.userQuestion).subscribe(
      (data:any)=>{
        this.msgService.showSuccess('Thankyou for your inquiry. We will get back to you soon.')
        this.userQuestion=new userQuestionForm({})
      },error=>{
        this.msgService.showWarning('Error! Please try again later.')
      }
    )
  }

}

import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { AddBlogpageComponent } from './add-blogpage/add-blogpage.component';
import { AddSampleComponent } from './add-sample/add-sample.component';
import { AddTestimonialsComponent } from './add-testimonials/add-testimonials.component';
import { AnzscoCodeComponent } from './anzsco-code/anzsco-code.component';
import { ConsultationComponent } from './consultation/consultation.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { EditBlogpageComponent } from './edit-blogpage/edit-blogpage.component';
import { FaqComponent } from './faq/faq.component';
import { InquiryComponent } from './inquiry/inquiry.component';
import { ListSampleComponent } from './list-sample/list-sample.component';
import { PageinfoComponent } from './pageinfo/pageinfo.component';
import { PricingCategoriesComponent } from './pricing-categories/pricing-categories.component';
import { PricingPackagesComponent } from './pricing-packages/pricing-packages.component';
import { SettingsComponent } from './settings/settings.component';
import { ExpertsComponent } from './experts/experts.component';
import { ViewBlogpageComponent } from './view-blogpage/view-blogpage.component';
import { ListFaqsComponent } from './list-faqs/list-faqs.component';
import { AssistanceComponent } from './assistance/assistance.component';
import { UserquestionComponent } from './userquestion/userquestion.component';
import { SpecialpricingComponent } from './specialpricing/specialpricing.component';




const generalRoute: Routes = [
    {
        path: 'addBlog',
        component: AddBlogpageComponent
    },
    {
        path: 'addSample',
        component: AddSampleComponent
    },
    {
        path: 'addTestimonials',
        component: AddTestimonialsComponent
    },
    {
        path: 'addAnzscoCode',
        component: AnzscoCodeComponent
    },
    {
        path: 'consultation',
        component: ConsultationComponent
    },
    {
        path: 'dashboard',
        component: DashboardComponent
    },
    {
        path: 'editBlog/:id',
        component: EditBlogpageComponent
    },
    {
        path: 'Addfaq',
        component: FaqComponent
    },
    {
        path: 'inquiry',
        component: InquiryComponent
    },
    {
        path: 'listSample-req',
        component: ListSampleComponent
    },
    
    {
        path: 'pageInfo',
        component: PageinfoComponent
    },
    {
        path: 'pricing-categories',
        component: PricingCategoriesComponent
    },
    {
        path: 'pricing-packages',
        component: PricingPackagesComponent
    },
    {
        path: 'setting',
        component: SettingsComponent
    },
    {
        path: 'expert',
        component: ExpertsComponent
    },
    {
        path: 'list-blog',
        component: ViewBlogpageComponent
    },
    {
        path: 'list-faqs',
        component: ListFaqsComponent
    },
    {
        path: 'assistance',
        component: AssistanceComponent
    },
    {
        path: 'userQuestion',
        component: UserquestionComponent
    },
    {
        path: 'special-pricing',
        component: SpecialpricingComponent
    },
    

    

]
@NgModule({
    imports: [RouterModule.forChild(generalRoute)],
    exports: [RouterModule]
})
export class dashboardRouterModule {

}

<div class="home-content1 ">
    <div class="home1">
      <div class="container">
        <div class="row">
          <h1>Lorem <span class="top_text_color">ipsum dolor</span> sit amet lorem ipsum</h1>
        </div>
        <div class="row px-5 login_form">
            <div class="col-md-12 col-xs-12 col-sm-12 col-lg-12 " >
                <form class="m-auto w-50 px-5 py-2" #registerForm="ngForm">
                    <div class=" form form-group ">
                        <label for="name">Email</label>
                        <input class="form-control" type="text" placeholder="Email" [(ngModel)]="user.email" name="Email" required
                        #emailis="ngModel">
                          <div class="danger" [hidden]="emailis.valid || emailis.pristine">
                        required field
                          </div>
                        
                        <div *ngIf= !btnclicked>
                            <button (click)="getdata()" class="btn btn-danger" [disabled]="!registerForm.valid">Submit</button> 
                        </div>
                        <button class="btn btn-success" *ngIf="btnclicked" disabled>Submitting...</button>
                    </div>
                </form>
                                    
            </div>
        </div>   
      </div>
    </div>   
</div>
  
<div *ngIf="addBackgroundShow">
    <div class="modal fade in" id="myModal" data-toggle="modal" style="display: block;">
        <div class="modal-dialog author-dialog">
          <div class="modal-content">
          
            <!-- Modal Header -->
            <div class="modal-header">
              <h4 class="modal-title text-danger">Add Testimonials</h4>
              <button type="button" class="close" data-dismiss="modal" (click)="addCategoriesModalClose()">&times;</button>
            </div>
            
            <!-- Modal body -->
            <div class="modal-body">
                <form class="form-group " #addBackgroundForm="ngForm">
                    <div class="row">
                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                            <h4 class="text-primary">Name: <span class="text-danger">(*)</span></h4>
                            <input class="form-control" type="text" placeholder="Name" [(ngModel)]="addTestimonials.name"
                                name="Name" required #nameis="ngModel">
                            <div class="danger" [hidden]="nameis.valid || nameis.pristine">
                                required field
                            </div> 
                            <h4 class="text-primary">Number of Stars: <span class="text-danger">(*)</span></h4>
                            <input class="form-control" type="number" placeholder="Number of Stars" [(ngModel)]="addTestimonials.star"
                                name="Star"  #staris="ngModel">
                                <h4 class="text-primary">Message: <span class="text-danger">(*)</span></h4>
                                <textarea class="form-control" type="text" placeholder="Message" [(ngModel)]="addTestimonials.message"
                                    name="Message"  #messageis="ngModel"></textarea>
                            

                            <div class="pp my-3" *ngIf="!imageSelected">
                                <label for="file-upload" class="custom-file-upload">
                                    <i class="fa fa-cloud-upload"></i> Click here to Upload Image <i class="fa fa-chevron-right" aria-hidden="true"></i>
                                </label>
                                <input id="file-upload" type="file"
                                    accept=".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*"
                                    (change)="fileChanged($event)"  />
                            </div>
                        </div>  
                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div class="buttons " *ngIf="imageSelected">
                                        <button class="btn btn-success" disabled>{{fileno}} Images</button>
                                        <button class="btn btn-danger" (click)="removeUploadedFile()">Remove All!</button>
                                    </div>
                                </div>                            
                            </div>
                            <div class="row">
                                <div class="pp text-center" *ngIf="imageSelected">
                                    <label for="file-upload" class="custom-file-upload">
                                        <i class="fa fa-cloud-upload"></i>Change All Images
                                    </label>
                                    <input id="file-upload" type="file" (change)="fileChanged($event)" accept=".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*" />
                                </div>
                        </div>
                        <div class="row">
                            <h5 class="text-success"><strong>New Upload Image</strong></h5>
                        </div>                    
                        <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12" *ngFor="let url of urls;let i=index" id="ram"
                                style="width:300px">
                                <img [src]="url" alt="thumbnail" class="img-thumbnail" style="width: 200px;height: 200px;">
                                <i class="fas fa-trash-alt" id="deleteIcon" (click)="removeNew(i)"></i>
                            </div>
                        </div>
                    </div>                                       
                    </div>
                    
                
                <div class="row mt-3">
                    <div class="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                        <div class="m-auto">
                            <button class="btn btn-success" (click)="submit()" *ngIf="!submitting"
                                [disabled]="addBackgroundForm.form.invalid">Submit</button>
                            <button disabled class="btn btn-info" *ngIf="submitting">Submitting...</button>
                        </div>
                    </div>
                </div>
                
                </form>
            </div>
            
            <!-- Modal footer -->
            <div class="modal-footer">
              <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="addCategoriesModalClose()">Close</button>
            </div>
            
          </div>
        </div>
      </div>
</div>



<div *ngIf="deleteAsk">
    <div class="modal fade in" id="deleteModal" data-toggle="modal" style="display: block;">
        <div class="modal-dialog">
          <div class="modal-content">
          
            <!-- Modal Header -->
            <div class="modal-header">
              <h4 class="modal-title text-danger">Delete Data From Server</h4>
              <button type="button" class="close" data-dismiss="modal" (click)="deleteModalClose()">&times;</button>
            </div>
            
            <!-- Modal body -->
            <div class="modal-body">
                <!-- <div *ngIf="imgShow">
                    <img src="{{imgUrl}}{{advInfo.image}}" class="img-fluid" alt="Responsive image" >  
                </div>                           -->
                
                <h5>Are you sure you want to delete the data?</h5>
                <div class="row">
                    <div class="col">
                        <button class="btn btn-danger" (click)="delete()">Yes</button>
                    </div>
                    <div class="col">
                        <button class="btn btn-primary" (click)="deleteModalClose()">No</button>
                    </div>
                </div>
            </div>
            
            <!-- Modal footer -->
            
            
          </div>
        </div>
      </div>
</div>

<div class="sidebar">
    <app-sidebar></app-sidebar>
</div>
<div class="main">
    <div class="bg">
        <div class="row">
            <div class="col-12">
                <h1 class="text-center p-5 my-5">Welcome to Client Side</h1>
            </div>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
            <div class="heading-text ">
                <h4 class="text-center">Testimonials List</h4>
            </div>
        </div>
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
            <button class="btn btn-danger" (click)="showAddCategoriesModal()" >Add Testimonials</button>
        </div>
    </div>
        <div class="row mt-3">
            <div class="container">
                <table mdbTable #tableEl="mdbTable" class="z-depth-1">
                  <thead>
                  <tr>
                    <th *ngFor="let head of headElements; let i = index" scope="col">{{head}}
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr *ngFor="let el of elements; let i = index">
                    <th *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex"
                        scope="row">{{el.id}}</th>
                    <td *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex">{{el.name}}</td>
                    <td *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex">
                        <img src="./../../../assets/images/cdr logo 1.png" style="height: 50px; width: 100px;" *ngIf="!el.image">
                        <img src="{{imageUrl}}{{el.image}}" style="height: 50px; width: 100px;" *ngIf="el.image">
                    </td>
                    <td *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex">{{el.star}}</td>
                    <td *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex">{{el.message}}</td>


                    <td *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex"><i class="fa fa-trash" aria-hidden="true" (click)="askDelete(true, el)"></i></td>
                  </tr>
                  </tbody>
                  <tfoot class="grey lighten-5 w-100">
                  <tr>
                    <td colspan="4">
                      <mdb-table-pagination [tableEl]="tableEl" [searchDataSource]="elements"></mdb-table-pagination>
                    </td>
                  </tr>
                  </tfoot>
                </table>
              </div>
        </div>
   
</div>
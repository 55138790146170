export class assistanceForm {
    name:string;
    email:string;
    phone:String;
    country:String;
    engineeringDiscipline:String;
    reportType:String;
    discription:String;
    termsAccepted:Boolean;

    cyear:Number;
    cmonth:Number;
    cday:Number;
  
  constructor(obj:any){
      this.name=obj.name||'';
      this.email=obj.email||'';
      this.phone=obj.phone||'';
      this.country=obj.country||'';
      this.engineeringDiscipline=obj.engineeringDiscipline||'';
      this.reportType=obj.reportType||'';
      this.discription=obj.discription||'';
      this.termsAccepted=obj.termsAccepted||'';

      this.cyear=obj.cyear||'';
      this.cmonth=obj.cmonth||'';
      this.cday=obj.cday||'';
  }
}